.contact-search {
	min-height: 600px;
	max-height: 600px;
	width: 100%;
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.contact-search__graphic {
	margin-top: 100px;
	max-width: 250px;
	align-self: center;
}

.contact-search__input {
	margin-top: 20px;
	margin-bottom: 30px;
	width: 100%;
}

.contact-search__input input {
	margin-top: 3px;
	font-size: 24px;
	border: none;
	border-bottom: 1px solid #b6b6b6;
	width: 100%;
}

.contact-search__input input::placeholder {
	color: #b6b6b6;
}

.contact-search__actions {
	align-self: flex-end;
	display: flex;
	flex-flow: row nowrap;
	margin-bottom: 10px;
}

.contact-search__search-results {
	width: 100%;
	flex: 1;
	/* height: 300px; */
	overflow: auto;
	margin-top: auto;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.contact-search__search-results__item {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	font-size: 20px;
	width: 100%;
	padding: 20px 10px;
}

.contact-search__search-results__subheader {
	margin-top: 20px;
}

.contact-search__search-results__item--hover {
	background-color: #f6f6f9;
	cursor: pointer;
}

.contact-search__search-results__item:hover {
	background-color: #f6f6f9;
	cursor: pointer;
}

.contact-search__search-results__item__icon {
	margin-top: 5px;
	margin-right: 10px;
}

.contact-search__search-results__item__phone {
	margin-right: 20px;
	margin-left: 10px;
	font-size: 16px;
	background-color: #f1f2f6;
	padding: 3px 7px 3px 7px;
	border-radius: 20px;
	color: #676a6c;
	white-space: nowrap;
	margin-right: 5px;
}

.contact-search__search-results__item__email {
	margin-right: 20px;
	margin-left: 10px;
	font-size: 16px;
	background-color: #f1f2f6;
	padding: 3px 7px 3px 7px;
	border-radius: 20px;
	color: #676a6c;
	white-space: nowrap;
	margin-right: 5px;
}

.contact-search__search-results__item__payment {
	margin-top: 3px;
	margin-left: auto;
}

.contact-search__search-results__item--disabled {
	color: #b4b6b8 !important;
}

.contact-search__search-results__item--disabled .contact-search__search-results__item__phone {
	background-color: #f7f7f7 !important;
	color: #b4b6b8 !important;
}

.contact-search__search-results__item__permission-denied {
	display: none;
	font-size: 16px;
}

.contact-search__search-results__item:hover .contact-search__search-results__item__permission-denied {
	display: block;
}
