.schedule-hours__table {
	width: 520px;
}

.schedule-hours__table--borderless {
	border: none !important;
}
.schedule-hours__table--responsive {
	overflow-x: auto;
}
.schedule-hours__table--tdPadding {
	padding-top: 13px !important;
	min-width: 60px;
}
.schedule-hours__table--closedLabel {
	position: relative;
	top: -8px;
	right: 3px;
}

.schedule-hours__table__input {
	width: 120px;
	border-radius: 5px;
}

.schedule-hours__table__input:focus {
	border-color: #60a9ff;
}

.schedule-hours__box {
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 15px;
}

.schedule-hours__save {
	margin-top: 25px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.schedule-hours__container {
	margin-left: 30px;
}

.schedule-hours__table__add-hours {
	cursor: pointer;
	display: inline-block;
	margin-bottom: 15px;
	margin-left: 84px;
}

.schedule-hours__table__timingRow {
	text-align: left;
}

.schedule-hours__table__timingRow__timing {
	display: inline-block;
	margin-bottom: 15px;
}

.schedule-hours__table__timingRow__divider {
	display: inline-block;
	margin-left: 10px;
	margin-right: 10px;
}

.schedule-hours__table__timingRow__delete {
	display: inline-block;
	vertical-align: middle;
	margin-left: 5px;
	opacity: 0.7;
}

.schedule-hours__table__timingRow__delete:hover {
	cursor: pointer;
}

.schedule-hours__table--align-centre {
	text-align: center;
}
