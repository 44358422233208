/** dnd drag-and-drop */

.dnd {
	width: 100%;
	position: relative;
	border: 2px solid transparent;
}

.dnd--is-dragging {
	border: 2px dashed #60a8ff;
	border-radius: 10px;
}

.dnd__overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: rgba(255, 255, 255, 0.6);
}

.dnd__overlay__text {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	text-align: center;
	font-weight: 300;
	color: #898a8a;
}

.dnd__overlay__text__icon {
	margin-right: 5px;
}

.dnd__drop-area {
	width: 100%;
	height: 100%;
	min-height: 100px;
	max-height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;

	border: 2px dashed #e5e6e7;
	border-radius: 10px;
	color: #898a8a;
	font-size: 14px;
}

.dnd__drop-area__click-here {
	text-decoration: underline;
	cursor: pointer;
}

.dnd__drop-area__files {
	margin: 15px 0;
	width: 100%;
	height: 100%;
}

.dnd__drop-area__files__file {
	padding: 5px 5px 5px 15px;
	border: 1px solid #e5e6e7;
	border-radius: 10px;
	width: 250px;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.dnd__drop-area__files__file__ext {
	font-size: 32px;
	margin-right: 15px;
}

.dnd__drop-area__files__file__name {
	font-size: 16px;
	margin-top: 2px;
	margin-right: auto;
	word-break: break-word;
}

.dnd__drop-area__files__file__x {
	margin-left: auto;
}
