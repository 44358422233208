.cb-container {
	min-width: 350px;
}

.cb-container hr {
	margin: 15px 0 15px 0;
}

.cb-feedback-section > div {
	width: 100%;
}

.cb-feedback-section-numbers {
	margin-right: 20px;
}

.cb-feedback-section-icon {
	position: relative;
	left: -15px;
}

.cb-feedback-section-icon svg {
	position: absolute;
	width: 18px;
	height: 18px;
}

.cb-feedback-section-icon__check {
	color: #18a689;
}

.cb-feedback-section-icon__x-circle {
	color: #ed5565;
}

.cb-feedback-section-button {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.cb-feedback-no-results {
	margin: 15px 5px;
}

.cb-report-feedback-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 10px;
}

.cb-report-feedback-all-failed {
	margin: 15px 5px;
}

.cb-report-feedback-row {
	display: flex;
	flex-direction: row;
}

.cb-report-feedback-row > div {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 7px 3px 4px 3px;
}

.cb-report-feedback-row:last-child > div {
	border: none;
}

.cb-report-feedback-row:first-child > div {
	font-weight: 600;
}

.cb-report-feedback-row > div:first-child {
	width: 40%;
	text-align: left;
}

.cb-report-feedback-row > div:nth-child(2) {
	width: 40%;
	text-align: left;
}

.cb-report-feedback-row > div:last-child {
	width: 20%;
	text-align: center;
}

.cb-report-feedback-row > div:last-child span {
	position: relative;
	top: -2px;
	left: -9px;
}

.cb-report-feedback-row > div:last-child span svg {
	position: absolute;
}

.cb-report-fail_color {
	color: #ed5565;
}

.cb-report-success_color {
	color: #18a689;
}

.cb-submit-message {
	margin: 10px;
}

.cb-submit__button {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
