.realtime {
    width: 100%;
    height: calc(100vh - 200px);
    
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.realtime__users {
    flex-grow: 1;
    height: 100%;
    margin: 0px 20px;
    
    overflow: auto;
}

.realtime__users__item {
    border: 1px solid #e5e6e7;
    border-radius: 8px;
    
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px 20px;
}

.realtime__users__item:hover {
    cursor: pointer;
    transition: background 0.2s;
    border: 1px solid #60a9ff;
}

.realtime__users__item__connections {
    margin-top: 20px;
}

.realtime__users__item__connections__item {
    border: 1px solid #e5e6e7;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px 20px;
}

.realtime__users__item__connections__item:hover {
    background-color: rgba(96, 169, 255, 0.2);
    cursor: pointer;
    transition: background 0.2s;
    border: 1px solid #60a9ff;
}

.realtime__selected {
    min-width: 50%;
    max-width: 50%;
    margin-left: auto;
    margin-right: 20px;
}