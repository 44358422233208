.dh-details__container {
	display: flex;
	flex-flow: row;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	min-height: 300px;
}

.dh-details-title {
	font-size: 36px;
	margin-top: 20px;
	margin-left: 40px;
	margin-bottom: 10px;
}

.dh-details {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 0px 20px 0px 20px;
	padding: 10px 20px;
	border: 1px solid rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	width: 100%;
}

.dh-details-info {
	width: 100%;
}

.dh-details-info .dh-details-title {
	font-size: 24px;
	margin-left: 0;
}

.dh-details-title a svg {
	position: relative;
	top: 5px;
	margin-right: 10px;
	width: 34px;
	height: 34px;
}

.dh-details-title--margin {
	margin-top: 30px;
}

.dh-details-sub-title {
	font-size: 24px;
	margin-left: 40px;
	margin-top: 20px;
}

.dh-details-item {
	margin-top: 20px;
	font-size: 14px;
}

.dh-details-row {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 20px 20px 20px 40px;
	padding: 20px;
	border: 1px solid rgba(0, 0, 0, 0.06);
	border-radius: 8px;
}

.dh-details-row div {
	flex: 1;
	padding: 2px;
}

.dh-details__save {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 20px;
	font-size: 14px;
}

@media only screen and (max-width: 767px) {
	.dh-details-row div {
		width: 100%;
		flex: auto;
		margin: 5px 0;
	}
}
