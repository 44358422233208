.ChatbotFaq__add-button {
	display: flex;
	align-items: center;
	width: fit-content;
}

.ChatbotFaq__title {
	display: flex;
	align-items: center;
}

.ChatbotFaq__back-button {
	width: 10%;
}

.ChatbotFaq__header {
	padding: 0 30px 15px;
}

.ChatbotFaqs__empty-faqs-msg {
	display: flex;
	justify-content: center;
}

.ChatbotFaqs__info-icon {
	color: blue;
	transform: scale(0.7);
}
.ChatbotFaqs__info-text {
	color: lightskyblue;
	margin-left: 5px;
	font-size: 18px;
	font-weight: 10;
}
.ChatbotFaqs__question-cell {
	display: flex;
	align-items: center;
}
.Chatbot__Faqs-edit-label {
	padding-top: 0 !important;
}

.ChatbotFaqs__list__actions {
	display: flex;
	align-items: center;
}

.ChatbotFaqs__conversation-ender {
	margin-left: 10px !important;
	margin-top: 0 !important;
}
