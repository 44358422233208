.mb-message-event-divider {
    text-align: center;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.mb-message-event-divider-line {
    border-bottom: 1px solid #E6E6E6;
    flex-grow: 1;
    margin: 0px 10px;
}

.mb-message-event-divider-content {
    word-break: break-word;
    font-size: 12px;
    padding: 8px 10px 5px 10px;
    border-radius: 7px;
    border: 1px solid #747d8c8b;
    max-width: 500px;
    color: #747d8c;
    margin: 0 10px;
}

.mb-message-event-divider-content p {
    margin-bottom: 0px;
}

.mb-message-event-divider-content--note {
    border-radius: 8px;
    background-color: #fffbe7;
    padding: 10px;
    position: relative;
    border: none;
}

.mb-message-event-divider-content--review {
    text-align: left;
}

.mb-message-event-divider-content--review p:first-child {
    text-align: center;
}

.mb-message-event-divider-content__disconnect__info {
    position: relative;
    top: 3px;
}

.mb-message-event-divider-content__get-started {
    margin: auto;
    margin-top: 10px;
    padding-top: 2px;
    height: 30px;
    max-width: 140px;
    padding: 2px 10px;
    font-size: 12px;
    border-radius: 8px;
    color: #fff;
    background-color: #60a9ff;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.mb-message-event-divider-content__get-started:hover {
    cursor: pointer;
    opacity: 0.8;
}
