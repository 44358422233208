.mb-ccsml {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 20px;
    overflow: scroll;
}

.mb-ccsml__back {
    width: 100%;
    font-size: 18px;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 15px;
    display: flex;
}

.mb-ccsml__item__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mb-ccsml__item__header__text {
    display: flex;
    flex-direction: row;
}

.mb-ccsmli__item__header__text__name {
    font-weight: 600;
    margin-right: 10px;
}

.mb-ccsml__back__message {
    margin-right: 5px;
    color: #60A9FF;
    font-weight: 600;
    width: fit-content;
    height: 27px;
}

.mb-ccsml__back__message:hover {
    cursor: pointer;
    opacity: 0.7;
}