/* sdm = send-draft-modal */

.sdm {
	min-width: 300px;
	width: 300px;
}

.sdm__text-bubble {
	padding: 0 20px 0 10px;
}
