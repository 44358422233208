input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}

.calendar {
	width: 100%;
	padding: 20px;
}

.calendar__actions {
	margin-left: auto;
}