/* Related to the giphy selecting component */
.mb-gifs {
    position: fixed;
    z-index: 1020;
    bottom: 0;
    height: 360px;
    background-color: white;
    margin: 10px 20px 55px 20px;
    border-radius: 8px;
    padding: 15px;
    overflow: hidden;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: calc(100% - 700px);
}

.mb-gifs-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 5px;
}

.mb-gifs__header {
    font-size: 22px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.mb-gifs__header__close {
    margin: 1px 15px 0px auto;
    cursor: pointer;
}

.mb-gifs__attribution{
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    align-self: flex-end;
    opacity: 0.2;
    margin-top: 9px;
    width: 110px;
}


.mb-gifs__header__close:hover {
    opacity: 0.8;
}


.mb-gifs__loading-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: black;
    transition: .2s;
    opacity: 0.7;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;

    text-align: center;
    font-size: 16px;
    cursor: default;
}

.mb-gifs__search{
    width: 100%;
    margin-bottom: 15px;
}

/* Related to giphy preview popup */

.mb-gif-preview {
    position: fixed;
    z-index: 1020;
    bottom: 0;
    background-color: white;
    margin: 10px 20px 55px 30px;
    border-radius: 8px;
    overflow-y: none;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-flow: column nowrap;
    width: 250px;
    overflow: hidden;
}


.mb-gif-preview__remove {
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0;
    background-color: black;
    color: white;
    padding: 60px 60px;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    font-size: 16px;
}

.mb-gif-preview:hover > .mb-gif-preview__remove {
    transition: .2s;
    opacity: 0.7;
    cursor: pointer;
}

.mb-gif-preview__image {
    object-fit: cover;
    overflow: hidden;
}
