.dh-page {
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

	flex-grow: 1;
	width: 100%;
	background-color: white;

	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;

	color: #5a5a5a;
	overflow: auto;
}

.dh-page-search {
	margin: 0 30px 30px 30px;
}

.dh-page-filters {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	font-size: 16px;
	margin-bottom: 10px;
	margin-left: 40px;
}

.dh-page-filters--items {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	margin-left: 20px;
	overflow: auto;
}

.dh-page-filters--title {
	opacity: 1 !important;
	font-weight: 600;
}

.dh-page-filters--selected {
	color: #60a9ff;
	opacity: 1 !important;
	text-decoration-line: underline;
}

.dh-page-filters--items > div {
	margin-right: 30px;
	opacity: 0.7;
}

.dh-page-filters--items > div:hover {
	opacity: 1;
	cursor: pointer;
}

.dh-page__datepicker {
	margin-left: 42px;
	display: flex;
	align-items: center;
}

.dh-page__datepicker--card {
	margin-left: 20px !important;
	margin-bottom: 5px;
}

.dh-page__chart-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-size: 14px;
}

.dh-page__cards,
.dh-page__body {
	width: 100%;
	padding: 0 20px;
}

.dh-page__cards .dashboard__card {
	margin: 20px 0;
}

.dh-page__cards .dashboard__card:first-child {
	margin-top: 0;
}

.dh-page__advances-filters {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0 30px;
	margin-bottom: 15px;
}

.dh-page__advances-filters__select {
	margin-top: 2px;
	margin-bottom: 0;
	margin-right: 15px;
	width: 200px;
}

.dh-page__advances-filters__select .select__label {
	margin-bottom: 0;
}

.dh-page__advances-filters__datepicker {
	margin-right: 15px !important;
}

.dh-page__advances-filters__datepicker__text {
	font-size: 12px;
	font-weight: 900;
	font-size: 12px;
}

.dh-page__advances-filters__datepicker input {
	height: 40px !important;
	max-width: 120px !important;
	border-radius: 8px !important;
}

.dh-page__advances-filters__datepicker .common__datepicker__date-range__text {
	font-size: 12px !important;
}

/* sm styles */
@media only screen and (max-width: 767px) {
	.dh-page-search {
		margin: 0 15px 30px 15px;
	}

	.dh-page-filters {
		margin-left: 10px;
	}
	.dh-page-title--data-selector {
		margin: 20px 20px 15px 20px;
	}
}

/* md styles */
@media only screen and (max-width: 991px) {
	.dh-page-title--data-selector {
		font-size: 36px;
		margin: 20px 40px 15px 40px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
}
