.Common-bg--dark {
	background-color: #323233 !important;
}

.Common__spinnerdiv--center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.Common__spinnerdiv--center-flex {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.Common__spinnerchild--center {
	width: 80px;
	margin: 90px auto;
}
.Common__iboxcontent--spinnerdiv--center {
	width: 0px;
	margin: auto;
}
.Common__table-responsive--noborder {
	border: none;
}
#wrapper-ie11 {
	width: 100%;
}
.body.mini-navbar #page-wrapper {
	width: 90px !important;
}
/* Override react select's z-index */
.Select-menu-outer {
	z-index: 999 !important;
}

.col-sm-nowidth {
	width: 8%;
}

.Common__react-tooltip {
	opacity: 1 !important;

	font-size: 13px;
	font-weight: 300;
}

.Common__noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.Common__cards {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
}

.Common__cards .Common__card {
	flex: 0 0 300px;
}

.Common__card {
	flex-direction: column;
	margin: 20px;
	padding: 16px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
	background-color: #ffffff;
	border-radius: 10px;
	font-size: 14px;
	text-align: center;
}

.Navigation-icon {
	display: inline-flex;
	position: relative;
	transform: translate(-3px, 3px);
}

.Common_navigation-left-border {
	border-left: 4px solid #2f4050;
}

.Common_download-btn {
	color: #676a6c;
}

.Common_flex-pull-right {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.nav-indicator {
	border-radius: 10px;
	margin-left: -4px;
	margin-top: -2px;
	width: 8px;
	height: 8px;
	background-color: #ed5565;
}

.Common_highlight-text {
	color: #333;
	font-weight: 600;
	background-color: #f5f5f5;
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	position: relative;
	top: -2px;
	padding-bottom: 2px;
}
.Common-toast-info {
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
	background-color: #262626 !important;
	color: white !important;
	padding-left: 25px !important;
	font-size: 18px !important;
	z-index: 10000000000000 !important;
}

.Common-toast-error {
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
	background-color: orangered !important;
	color: white !important;
	padding-left: 25px !important;
	font-size: 18px !important;
	z-index: 10000000000000 !important;
}
.Common__cursor--pointer {
	cursor: pointer;
}
.Common__no--margin {
	margin: 0;
}
.Common__color--green {
	color: green;
}
.Common__color--orange {
	color: orange;
}
.Common__color--dh-blue {
	color: #60a9ff;
}
.Common__vertical--center-align {
	display: flex;
	align-items: center;
}
.Common__input--counter {
	text-align: right;
	font-size: 12px;
}
.Common__font--15 {
	font-size: 15px;
}
.Common__font--18 {
	font-size: 18px;
}
.Common__font--14 {
	font-size: 14px;
}
.Common__font--10 {
	font-size: 10px;
}
.Common__flex {
	display: flex;
}
.Common__margin-left-8 {
	margin-left: 8px;
}
.Common__dh-modal .modal-content {
	border-radius: 15px;
}

.Common__dh-modal .modal-header {
	border-radius: 15px;
}

.Common__dh-modal .modal-title {
	text-align: center;
}

.Common__dh-modal .close {
	font-size: 30px;
}

.Common__dh-page-header--with-back-button {
	margin-left: 38px;
}

.Common__dh-back-button {
	cursor: pointer;
	user-select: none;
	transform: translate(0px, -1px);
	position: absolute;
}

.Common__dh-back-button svg {
	width: 30px;
	height: 30px;
}

.Common__feedback-success {
	color: #18a689;
}

.Common__feedback-fail {
	color: #ed5565;
}

.Common__chart-container {
	width: 100%;
	padding-left: 45px;
	padding-right: 45px;
}

/* .Common__chart-container svg {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
} */

.Common__flex-grow {
	width: 400px;
	display: flex;
	flex-grow: 1;
	flex-flow: column nowrap;
}

.Common__search {
	margin: 0 30px 15px 30px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
}

.Common__datepicker {
	border: 1px solid #e5e6e7;
	border-radius: 8px;
	padding: 8px 14px;
}

.Common__input {
	background-color: #ffffff;
	background-image: none;
	border: 1px solid #e5e6e7;
	border-radius: 8px;
	color: inherit;
	display: block;
	padding: 6px 12px;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	height: 40px;
	width: 100%;
	font-size: 14px;
	line-height: 1.4;
	box-shadow: none;
}

.Common__input:focus {
	border-color: #66afe9;
	box-shadow: none;
}

.Common__input:disabled {
	cursor: not-allowed;
	background-color: #eee;
	opacity: 1;
}

.Common__input::placeholder {
	font-size: 14px !important;
	color: #b0b6b9;
	opacity: 1;
}

.Common__input::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	font-size: 16px;
}
.Common__input::-moz-placeholder {
	/* Firefox 19+ */
	font-size: 16px;
}
.Common__input:-ms-input-placeholder {
	/* IE 10+ */
	font-size: 16px;
}

.common__datepicker__date-range {
	display: flex;
	flex-direction: column;
	margin: 0 0 15px 40px;
}

.common__datepicker__date-range__text {
	cursor: pointer;
	position: relative;
	width: fit-content;
	font-size: 16px;
	font-weight: 600;
}

.Common__csv-link {
	margin-left: auto;
	position: relative;
}
