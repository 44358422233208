.not-found {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 50px;
}

.not-found__img {
	max-width: 50%;
}

.not-found__title {
	font-size: 28px;
	font-weight: 900;
	margin-bottom: 20px;
}

.not-found__subtext {
	font-size: 14px;
}
