.workflows {
	padding: 0 30px;
	width: 100%;
}

.workflows__add-more {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 15px;
	color: #999999;
}

.workflows__add-more__icon {
	cursor: pointer;
	width: 60px;
	height: 60px;
	padding: 4px 6px;
}

.workflows__add-more__icon:hover {
	transition: background-color 0.3s, color 0.3s, border 0.3s;
	background-color: #e8ecf1;
	color: inherit;
	border-radius: 5px;
}

.workflows-actions__recipes {
	position: relative;
}
