.dh-action {
	height: 30px;
	width: 30px;
	border-radius: 5px;
	margin-right: 10px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	background-color: #f6f6f9;
	color: #5a5a5a;
	text-decoration: none;
	border: 1px solid rgba(0, 0, 0, 0);
}

.dh-action--transparent {
	background-color: rgba(0, 0, 0, 0);
}

.dh-action--outline {
	border: 1px solid hsl(0, 0%, 90%);
}

.dh-action--active {
	cursor: pointer;
	background-color: #60a9ff;
	color: white;

	opacity: 1 !important;
	transition: background 0.3s, color 0.3s;
}

.dh-action--text {
	width: fit-content;
	padding-left: 5px;
	padding-right: 5px;
}

.dh-action--disabled {
	opacity: 0.6;
	cursor: not-allowed !important;
}

.dh-action:hover {
	cursor: pointer;
	background-color: rgba(96, 169, 255, 0.2) !important;
	border: 1px solid #60a9ff !important;
	color: #60a9ff !important;

	opacity: 1 !important;
	transition: background-color 0.3s, color 0.3s, border 0.3s;
}

.dh-action--disabled:hover {
	cursor: not-allowed !important;
	opacity: 0.6;
}

.dh-action--active:hover {
	background-color: #3b84d9;
}

.dh-action--dark:hover {
	background-color: #e8ecf1;
	border: 1px solid #e8ecf1;
	color: inherit;
}
