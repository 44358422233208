.micro-site-config {
	margin-left: 30px;
	margin-bottom: 30px;
}

.micro-site-config__loader {
	margin-top: 60px;
	margin-left: auto;
	margin-right: auto;
}

.micro-site-config__loader .Spinners__tail-fade {
	font-size: 80px !important;
	width: 80px;
	height: 80px;
}

.micro-site-config__visit-site {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.micro-site-config__visit-site__icon {
	width: 14px;
	height: 14px;
}

.micro-site-config__banner-image {
	margin-top: 10px;
	max-width: 100%;
	max-height: 200px;
	object-fit: contain;
	border-radius: 8px;
	display: flex;
	justify-content: flex-start;
}

.micro-site-config__banner-image--inavlid {
	display: none;
}

.micro-site-config__no-image {
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	background-color: #f6f6f9;
	border-radius: 8px;
}

.micro-site-config__banner-image {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
}

.micro-site-config__banner-image__btn {
	position: relative;
}

.micro-site-config__banner-image__input {
	display: none !important;
}

.micro-site-config__banner-image__text {
	padding-top: 3px;
}
