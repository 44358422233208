.location-features-container {
	width: 425px;
	margin-left: 30px;
	margin-bottom: 80px;
}

.location-features-list-header {
	margin-top: 20px;
	margin-right: 22px;
	font-size: 21px;
}

.location-features-list {
	margin-top: 5px;
	margin-right: 20px;
	width: 100%;
}

.location-features-item {
	height: 50px;
	display: flex;
	flex-flow: row nowrap;
	padding: 10px 20px;
	align-items: center;
	justify-content: space-between;
	font-size: 15px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	margin-bottom: -1px;
}

.location-features-item:first-child {
	border-radius: 10px 10px 0 0;
}
.location-features-item:last-child {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 0 0 10px 10px;
}

.location-features-item--changed {
	border-color: #add2ff !important;
	color: #60a9ff;
}

.location-features-item__icon {
	margin-left: 5px;
	position: relative;
	top: 2px;
}

.location-features-buttons {
	margin: 10px 0;
	display: flex;
	justify-content: center;
}

.location-features-buttons button {
	margin: 0 5px;
}

.location-features-save-feedback {
	margin-top: 10px;
	height: 10px;
	text-align: center;
}

.location-features-save-feedback svg {
	height: 16px;
	width: 16px;
	position: relative;
	top: 3px;
}

.location-features-save-feedback span {
	transition: color 0.5s ease;
}

.location-features-save-feedback .success svg {
	color: #18a689;
}
.location-features-save-feedback .error svg {
	color: #ed5565;
}

@media (max-width: 767px) {
	.location-features-container {
		width: 450px;
		margin: 0 auto;
	}

	.location-features-buttons {
		margin: 10px 0;
		display: flex;
		justify-content: center;
	}
}

@media (max-width: 540px) {
	.location-features-container {
		width: 90%;
		margin: 0 auto;
	}
}
