.mb-message-team {
    font-size: 15px;
    width: 100%;
    margin-right: 30px;
    position: relative;
    min-height: 32px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 30px 0px 30px;
    z-index: 1000;
}

.mb-message-team-set {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin-right: 20px;
}

.mb-message-team-set__media--horizontal {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.mb-message-team-set__media--horizontal > .thread-media {
    margin-right: 10px;
}

.mb-message-team--reference {
    background-color: rgba(96, 169, 255, 0.2);
    animation: blinkingBackground 0.5s 3;
}

@keyframes blinkingBackground{
    0%		{ background-color:  rgba(96, 169, 255, 0.2)}
    50%		{ background-color: white;}
    100% { background-color:  rgba(96, 169, 255, 0.2)}
}

.mb-message-team-content {
    padding-top: 7px;
    padding-bottom: 7px;
}

.mb-message-team-context {
    opacity: 0;
    margin-top: 5px;
}

.mb-message-team-context:hover {
    opacity: 0.8 !important;
}

.mb-message-team:hover .mb-message-team-context {
    opacity: 1;
    cursor: pointer;
}

.mb-message-team:hover .mb-message-reaction {
    opacity: 1;
    cursor: pointer;
}

.mb-message-team:hover{
    background-color: #F6F6F9;
}


.mb-message-team--hide-options {
    border-radius: 8px;
}

.mb-message-team--hide-options:hover {
    background-color: #FFFFFF;
    border-radius: 8px;
}

.mb-message-date {
    min-width: 50px;
    opacity: 0;
    margin-left: auto;
    margin-right: 10px;
    font-size: 12px;
}

.mb-message-team:hover .mb-message-date {
    opacity: 1;
}

.mb-message-date--edited {
    margin-left: -10px !important;
}

/* .mb-message-internal .mb-message-top {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
} */

/* .mb-message-internal .mb-message-top:hover {
    background-color: #F6F6F9;
} */

/* REACTION BUTTON */

.mb-message-reaction {
    opacity: 0;
    margin-top: 5px;
}

.mb-message-reaction:hover {
    opacity: 0.8 !important;
}

/* TEAM EDIT MESSAGE */

.mb-message-edit {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-bottom: 15px;
    width: 100%;

    border-radius: 8px;
    border: 1px solid #E6E6E6;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    padding: 12px 12px;
    position: relative;

    background-color: white;
}

.mb-message-edit__actions {
    margin-left: auto;
    margin-top: 10px;
    display: flex;
    flex-flow: row nowrap;
    position: absolute;
    top: 0px;
    right: 10px;

    user-select: none;
}

.mb-message-edit-input {
    margin-top: 3px;
    font-size: 15px;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    padding: 15px;
    min-width: 10px;
    min-height: 80px;
    flex-grow: 1;
    width: 100%;
}

.mb-message-edit-input::placeholder {
    color: #B6B6B6;
}

.mb-message-edit-button {
    margin-left: 7px;
    font-size: 14px;
    padding: 6px 10px 4px 10px;
    border-radius: 50px;
}

.mb-message-edit-button--disabled {
    opacity: 0.5;
}

.mb-message-edit-button--success {
    border: 2px solid #60A9FF;
}

.mb-message-edit-button--cancel {
    border: 2px solid #e74c3c;
}

.mb-message-edit-button--success:hover {
    border: 2px solid #60A9FF;
    background-color: #60A9FF;
    color: white;
    cursor: pointer;
}

.mb-message-edit-button--cancel:hover {
    border: 2px solid #e74c3c;
    background-color: #e74c3c;
    color: white;
    cursor: pointer;
}

.mb-toast-undo {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.mb-toast-undo-button {
    margin-left: 7px;
    font-size: 14px;
    padding: 6px 10px 4px 10px;
    border-radius: 50px;
    border: 2px solid #ffffff;
    margin-right: 15px;
}

.mb-toast-undo-button:hover {
    border: 2px solid #ffffff !important;
    background-color: #ffffff !important;
    color: black !important;
    cursor: pointer !important;
}

.mb-message-reply {
    border: 1px solid #E6E6E6;
    border-left-width: 5px; 
    width: fit-content;
    margin-right: 20px;
    border-radius: 8px;
    padding: 8px 10px 5px 10px;
    font-size: 11px;
    margin-top: 5px;
    min-width: 200px;
}

.mb-message-reply:hover{
    cursor: pointer;
}

.mb-message-reply-name {
    font-weight: 900;
}

.mb-message-reply-content {
    margin-top: 3px;
}

.mb-teamchat-youtube-container{
    position: relative;
    width: 426px;
    height: 240px;
}


.mb-teamchat-youtube-iframe{
    width: 100%;
    height: 100%;
}

.mb-message-team-forward {
    width: 100%;
    position: relative;
}

.mb-message-team-forward__message {
    margin-left: 45px;
    margin-bottom: 8px;
    max-width: 400px;
    width: 400;
    min-width: 400px;
    padding: 8px;

    border-radius: 8px;
    border: 2px solid #F6F6F9;
    background-color: #F6F6F9;
}

.mb-message-team-forward__message__header {
    font-size: 15px;
    font-style: italic;
    margin-bottom: 15px;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.mb-message-team-forward__message__header:hover { 
    cursor: pointer;
}

.mb-message-team-forward__message__text {
    font-size: 15px;
}

.mb-message-team--task-incomplete{
    background-color: #fff0f0;
}