.mb-mentions {
    position: fixed;
    z-index: 1021;
    bottom: 143px;
    height: 250px;
    background-color: white;
    margin: 10px 20px 0px 30px;
    border-radius: 8px;
    width: 400px;
    padding: 15px;
    overflow-y: auto;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

.mb-mentions-header {
    font-size: 22px;
    margin-bottom: 10px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
}

.mb-mentions-header-title {

}

.mb-mentions-header-x {
    margin-left: auto;
    cursor: pointer;
}

.mb-mentions-header-x:hover {
    opacity: 0.8;
}

.mb-mentions-list {
}

.mb-mentions-list-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    border-radius: 8px;
    border: 1px solid white;
    font-size: 15px;
    font-weight: 500;
    padding: 7px 10px 5px 10px;

    margin-bottom: 4px;
}

.mb-mentions-list-item--selected {
    background-color: rgba(96, 169, 255, 0.2);
    border: 1px solid #60A9FF;
    cursor: pointer;
    transition: background 0.2s, border 0.2s;
}

.mb-mentions-list-item:hover {
    cursor: pointer;

    background-color: rgba(96, 169, 255, 0.2);
    border: 1px solid #60A9FF;
    transition: background 0.2s, border 0.2s;
}

.mb-mentions-list-item-content {
    width: 90%;
}

.mb-mentions-list-item-content:hover {
    color: #60A9FF;
}

.mb-mentions-list-item-content-name {
    font-size: 16px;
    margin-bottom: 5px;
}

.mb-mentions-list-item-content-name--selected {
    color: #60A9FF;

    background-color: rgba(96, 169, 255, 0.2);
    border: 1px solid #60A9FF;
	cursor: pointer;
	transition: background 0.2s, border 0.2s;
}

.mb-mentions-list-item-content-text {
    font-size: 16px;
}

.mb-mentions-list-item-actions {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto
}

.mb-mentions-list-item-action {
    margin-right: 10px;
}

.mb-mentions-list-item-action:hover {
    opacity: 0.8;
}
