.dh-legacy-page {
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

	flex-grow: 1;
	width: 100%;

	overflow: auto;

	color: #5a5a5a;
	background-color: #f6f6f9;

	padding-left: 15px;
	padding-right: 15px;
}
