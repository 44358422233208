.task__single-task {
    display: flex;
    flex-direction: column;

    position: relative;
    width: 310px;
    height: 310px;
    padding: 10px;

    border-radius: 8px;

    color: black;

    margin-right: 30px;
    margin-bottom: 30px;

    box-shadow: 4px 4px #0000000a;
}

.task__single-task--done {
    opacity: 0.3;
}

.task__task-title{
    font-size: 16px;
    padding-bottom: 12px;
    margin-bottom: 12px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.task__bottom__info{
    font-size: 12px;
    font-style: italic;
    opacity: 0.5;
    text-transform: capitalize;
}

.task__task-controls{
    text-align: right;
}

.task__task-control{
    display: inline-block;
    padding-right: 6px;
    cursor: pointer;   
}

.task__info{
    display: flex;
    position: absolute;
    bottom: 20px;
    right: 20px;
    
    width: 35px;
    height: 35px;

    padding-top: 3px;
    
    border-radius: 50px;
    overflow-y: auto;
    
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    
    font-size: 16px;
    font-weight: 900;
    color: #5a5a5a;
    
    background-color: #f6f6f9;
    border: 1px solid rgba(0, 0, 0, 0);
    
}


/* To make the mark up look nice */
.task__task-content{
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 12px;
    white-space: pre-line;
    word-break: break-word;
}

.task__task-content p {
    margin: 0;
}

.task__task-content p + ul, 
.task__task-content p + ol,
.task__task-content ol + ul,
.task__task-content ul + ol
{
    margin-top: -20px;
    margin-bottom: 0px;
}

.task__task-content ol, .task__task-content ul {
    line-height: 1em;
    padding-inline-start: 20px;
}

.task__task-content hr
{
    margin-top: 0px;
    margin-bottom: 0px;
    border-top: 1px solid black;
    margin-left: 0px;
    margin-right: 5px;
}

.task__task-content blockquote {
    padding: 0px 0px 0px 5px;
    margin: 0 0 0 0;
    font-size: 14px;
    border-left: 5px solid #eee;
}

.task__task-content code {
    background-color: inherit;
    border-radius: inherit;
    color: inherit;
    font-size: inherit;
    padding: inherit;
    white-space: inherit;
    font-family: inherit;
}

.task__task-content img {
    max-width: 100%;
    padding: 10px;
}

.task__task-content h1, .task__task-content h2, .task__task-content h3, .task__task-content h4, .task__task-content h5, .task__task-content h6{
    font-size: inherit;
}

/* Pinned ribbon */
.task__ribbon {
    background: #9EE0FF;
    height: 11px;
    width: 15px;
    position: absolute;
    top: 0;
    left: 9px;
    opacity: 0.5;
}

.task__ribbon:after,.task__ribbon:before {
    content: '';
    position: absolute;
    border-top: 7px solid #9EE0FF; 
    height: 0; 
    width: 0;
    top: 100%;
}

.task__ribbon:after {
    border-left: 15px solid transparent;
    right:0;
}

.task__ribbon:before {
    border-right: 15px solid transparent;
    left:0;
}
