.profile-picture__container {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 20px;
}

.profile-picture__circle {
	width: 150px;
	height: 150px;

	font-size: 40px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;

	border: 5px solid rgba(255, 255, 255, 0.7) !important;
	box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.12);
	border-radius: 1000px !important;

	overflow: hidden;
	background-color: #f2f2ff;
}

.profile-picture__initials {
	font-weight: 900;
	padding-top: 12px;
}

.profile-picture__image {
	width: 100%;
	height: 100%;
	display: block;
}

.profile-picture__file_upload {
	display: none !important;
}

.profile-picture__controls {
	position: relative;
	right: 33px;
	top: 120px;
	color: #666666;
}

.profile-picture__upload_button {
	font-size: 1.2em;
	transform: scale(1.5);
	cursor: pointer !important;
	border-radius: 50%;
	background-color: white;
	padding: 6px;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
}

.upload-button:hover {
	transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	color: #999;
}
