.mb-cc__search-input {
    font-size: 24px;
    border: none;
    width: 100%;
}

.mb-cc__subtitle {
    padding-left: 5px;
    margin-bottom: 10px;
    width: 100%;
}

.mb-cc__search {
    margin-top: 15px;
    margin-bottom: 15px;
    min-height: 400px;
    width: 540px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.mb-cc__search__members {
    margin-top: 5px;
    border-radius: 10px;
    border: 1px solid #f2f2f2;
    width: 250px;
    margin-right: 20px;
}

.mb-cc__search__members__item {
    display: flex;
    flex-flow: row nowrap;
    padding: 9px 16px;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    border-bottom: 1px #f2f2f2 solid;
}

.mb-cc__search__members__item__remove-icon {
    margin-left: auto;
    color: #ec4758;
}

.mb-cc__search__members__item__remove-icon:hover {
    opacity: 0.8;
    cursor: pointer;
}

.mb-cc__search__members__item__add-icon {
    margin-left: auto;
    color: #60A9FF;
}

.mb-cc__search__members__item__add-icon:hover {
    opacity: 0.8;
    cursor: pointer;
}

.mb-cc__channel-type__select {
    width: 100%;
    margin-bottom: 13px;
}

.manage-conversation-group__select {
    width: 470px;
}