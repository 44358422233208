.mb-templates {
    position: fixed;
    z-index: 1021;
    bottom: 0;
    height: 500px;
    background-color: white;
    margin: 10px 20px 59px 20px;
    border-radius: 8px;
    overflow-y: hidden;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.mb-templates--collapsed {
    width: calc(100% - 700px);
}

.mb-templates--expanded {
    width: calc(100% - 880px);
}

.mb-mentions-list-item-content--selected {
    background-color: #F1F1F1;
}

.mb-mentions-list-item-content--selected:hover {
    background-color: #e0e0e0;
}
