.dashboard {
	display: block;
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	color: #5a5a5a;
	background-color: #f6f6f9;
	padding: 0;
}

.dashboard .row {
	width: 100%;
	padding: 0;
	margin: 0;
}

.dashboard__header {
	background-color: #ffffff;
	padding: 20px 10px 20px 25px;
	margin-bottom: 10px;
}

.dashboard__header .dh-header-title {
	font-size: 24px;
}

.dashboard__center-chart {
	display: flex;
	align-items: center;
	justify-content: center;
}

.dashboard__card {
	position: relative;
	padding: 20px;
	background-color: #ffffff;
	color: #5a5a5a;

	border: solid 1px #ecf0f1;
	border-radius: 8px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
	width: 100%;
	min-width: 320px;
	margin: 0;
}

/* Overwrites the dashboard card heights */
.manage-dashboard__layout .dashboard__card {
	height: 100% !important;
}

.manage-dashboard__layout .input {
	width: 400px;
}

/**
* Grid layout heights
*/

.dashboard__card--h-1 {
	height: 50px;
}

.dashboard__card--h-2 {
	height: 100px;
}

.dashboard__card--h-3 {
	height: 150px;
}

.dashboard__card--h-4 {
	height: 250px;
}

.dashboard__card--h-5 {
	height: 340px;
}

.dashboard__card--h-6 {
	height: 400px;
}

.dashboard__card--h-7 {
	height: 420px;
}

/* 
At these larger height, some things just start to behave kinda wonky
like: Conversion and appointment analytics
*/
.dashboard__card--h-10 {
	height: 550px;
}

.dashboard__card--h-11 {
	height: 600px;
}

.dashboard__card--h-12 {
	height: 650px;
}

.dashboard__card--h-13 {
	height: 700px;
}

.dashboard__card--h-14 {
	height: 750px;
}

.dashboard__card--h-15 {
	height: 800px;
}

.dashboard__card--h-16 {
	height: 850px;
}

.dashboard__card--overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.dashboard__card--reeng-conversions {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.dashboard__card--reeng-revenue {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.dashboard__card--payments {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.dashboard__card--total-messages {
}

.dashboard__card--total-messages__header {
	position: absolute;
	z-index: 1;
	font-size: 21px;
	margin-bottom: 5px;
}

.dashboard__card--total-messages__chart {
	display: flex;
	align-items: center;
	justify-content: center;
}

.dashboard__card--keyword-usage {
	display: flex;
	flex-direction: column;
}

.dashboard__card h4 {
	margin: 0;
}

.dashboard__card h3 {
	font-size: 16px;
	font-weight: 600;
	margin-top: 10px;
}

.on-track {
	background-color: #60af44;
}

.dashboard__card__header--reviews-to-action {
	width: 300px;
}

.dashboard__card__reviews-respond-to {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
}

.dashboard__card__reviews-respond-to__text {
	margin-left: 25px;
	text-align: right;
}

.dashboard__card__reviews-respond-to__text__number {
	font-size: 36px;
}

.dashboard__card__reviews-respond-to__button {
	align-items: flex-end;
}

.dashboard__widget {
	background: white;
	padding: 10px 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.dashboard__card__header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.dashboard__card__header--full-width {
	width: 100%;
}

.dashboard__card__header__name {
	margin: 0 0 15px 0;
	font-size: 21px;
	overflow: hidden;
	white-space: nowrap;
}

.dashboard__card__header__name--margin-left {
	margin-left: 20px;
}

.dashboard__widget .dashboard__card__header__name {
	margin-bottom: 0;
}

.dashboard__card__header__icon {
	margin-left: 5px;
}

.dashboard__card__header__actions {
	margin-left: auto;
	margin-bottom: 15px;
}

.dashboard__card__header__actions .Common__csv-link {
	margin-right: 0;
}

.dashboard__card__stat {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
}

.dashboard__card__stat__count {
	margin: 0;
	font-size: 30px;
}

.dashboard__card___datepicker {
	margin: 0 0 15px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.dashboard__widget__conversions {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	width: 100%;

	text-align: left;
}

.dashboard__card--conversions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.dashboard__card--conversions .dashboard__widget__header__icon {
	justify-self: flex-start;
	padding-left: 20px;
	padding-right: 20px;
}

.dashboard__widget__payments {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-end;
	width: 100%;
}

.dashboard__widget__reengagement-attribution--get-started {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}

.dashboard__widget__payments--get-started {
	align-items: center;
}

.dashboard__widget__reengagement-attribution--get-started {
	align-self: center;
}

.dashboard__widget__payments__text {
	font-size: 14px;
	text-align: center;
	flex-grow: 1;
	margin-right: 10px;
}

.dashboard__widget__reengagement-attribution__text {
	font-size: 14px;
	text-align: center;
	width: 100%;
	margin-bottom: 0;
}

.dashboard__widget__payments--get-started .mb-button,
.dashboard__widget__reengagement-attribution--get-started .mb-button {
	text-wrap: nowrap;
}

@media screen and (max-width: 1400px) {
	.manage-dashboard {
		min-width: 600px;
	}
}

@media screen and (max-width: 768px) {
	.dashboard__card--goal {
		margin-bottom: 25px;
	}

	.dashboard__widget {
		margin-bottom: 25px;
	}

	.dashboard .row {
		margin-top: -15px;
	}
}

.manage-dashboard__loading {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 30px;
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.manage-dashboard__loading--dashboard {
	margin-top: 95px;
}

.manage-dashboard__loading--view {
	margin-top: 60px;
}

.manage-dashboard__loading > svg {
	margin: auto;
	width: 100%;
}

.manage-dashboard__card--manage {
	cursor: grab;
}

.manage-dashboard__layout {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.manage-dashboard__back {
	margin-right: 10px;
}

.manage-dashboard__card__remove {
	position: absolute;
	right: -40px;
	margin-right: 0;
	top: 50%;
	transform: translateY(-50%);
}

.add-dashboard-card__cards {
	display: flex;
	flex-direction: column;
	height: 400px;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 10px;
}

.add-dashboard-card__cards__item {
	padding: 16px;
	background-color: #ffffff;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	border: solid 1px #ecf0f1;
	border-radius: 10px;
	margin-bottom: 10px;
}

.add-dashboard-card__cards__item:hover {
	opacity: 0.7;
	cursor: pointer;
}

.add-dashboard-card__cards__item--selected {
	border-color: #60a9ff;
	background-color: rgba(96, 169, 255, 0.2);
}

.dashboard__widget__reengagement-attribution {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	padding: 0 10px;
}

.progress {
	margin-bottom: 15px;
}
