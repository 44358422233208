.maintenance {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
	background-color: white;

	padding-left: 30px;
	padding-right: 30px;
}

.maintenance__img {
	max-width: 50%;
	margin-bottom: 30px;
}

.maintenance__title {
	font-size: 28px;
	font-weight: 900;
	margin-bottom: 20px;
}

.maintenance__subtext {
	font-size: 14px;
	padding-left: 10px;
	padding-right: 10px;
}

.maintenance__open-intercom {
	margin-top: 20px;
}