.thread-media {
    margin-top: 10px;
    margin-bottom: 7px;
    border-radius: 5px;
}

.thread-media__image {
    cursor: pointer;
}

.thread-media__video {
    max-width: 350px;
}

.thread-media__file,
.thread-media__audio {
    border: 1px solid #E6E6E6;
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    
    min-width: 200px;
    max-width: 350px;
    max-height: 350px;

    border-radius: 8px;
}

.thread-media__file__container {
    padding: 10px 10px 10px 10px;
    width: 100%;
    background: #e5e5e5;
    color: #333333;
}

.thread-media__file:hover {
    background-color: rgba(96, 169, 255, 0.2);
    border: 1px solid #60A9FF;
    cursor: pointer;
    transition: background 0.2s, border 0.2s;
}

.thread-media__file__filename,
.thread-media__audio__filename {
    width: 100%;
    background-color: #f6f6f9;
    color: #333333;

    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.thread-media__audio__filename {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.thread-media__audio__filename__download {
    margin-left: 10px;
}

.thread-media__audio__filename__download:hover {
    cursor: pointer;
    opacity: 0.7;
}

.thread-media__audio__filename__text {
    max-width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}