/* bwsc === booking-widget-services-config */

.bwsc {
	padding: 0 30px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	width: 100%;
}

.bwsc__header {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
}

.bwsc__body {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.bwsc__body__options {
	width: 500px;
	min-width: 400px;
}

.bwsc__body__edit {
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: center;
}

.bwbs__modal {
	width: 100%;
}

.bwbs__modal__row {
}

.bwbs__modal__row--toggle {
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.bwbs__modal__row__title {
	margin-top: 10px;
	margin-bottom: 5px;
	font-weight: 600;
}

.bwbs__modal__row__time-input {
}

.bwbs__modal__row__item {
}

.bwbs__modal__row__inputs {
	margin: auto;
	display: flex;
	align-items: center;
}

.bwbs__modal__row__inputs__item {
	width: 100px;
	padding: 0 10px 10px 0;
}

.cbs__create_modal__language_container {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-end;
	position: relative;
}
