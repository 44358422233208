.appointments-search {
	margin-top: 8px;
	margin-bottom: 8px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
}

.appointments__date-range {
	display: flex;
	flex-direction: column;
	margin: 0 0 15px 40px;
}

.appointments__date-range__text {
	cursor: pointer;
	position: relative;
	width: fit-content;
	font-size: 16px;
	font-weight: 600;
}

.appointments__header__actions {
	margin-left: auto;
	display: flex;
	align-items: center;
	margin-right: 20px;
}

.appointments__header_controls {
	padding: 0 30px;
	margin-bottom: 15px;
	width: 100%;
	display: flex;
	align-items: center;
}
