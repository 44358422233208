.contacts-search {
	width: 100%;
	padding: 0 30px 30px 30px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	position: relative;
}

.contacts-search .search-input {
	width: 100%;
}

.contacts__record-checkbox {
	display: flex;
	height: 30px;
	margin-right: 5px;
	margin-top: 6px;
}

.contacts__actions {
	display: flex;
	align-items: center;
}
