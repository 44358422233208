/* sslm = send-short-links-modal */

.sslm {
    position: fixed;
    z-index: 1021;
    bottom: 0;
    height: 250px;
    width: 425px;
    background-color: white;
    margin: 10px 20px 59px 30px;
    padding: 15px 0;
    border-radius: 8px;
    overflow-y: auto;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.sslm__header {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-left: 20px;
    margin-bottom: 5px;
}

.sslm__header__text {
    margin-right: 10px;
}

.sslm__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.sslm__header__create {
    cursor: pointer;
}

.sslm__close:hover,
.sslm__header__create:hover {
    opacity: 0.8;
}

.sslm__header__limit {
    color: #333;
    opacity: 0.7;
    font-size: 12px;
}

.sslm__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.sslm__item__link {
    font-size: 14px;
    padding: 7px 10px;
    margin: 0 10px 0 10px;
    width: 100%;
    display: flex;
    align-items: baseline;
    border-radius: 8px;
}

.sslm__item__link:hover{
    cursor: pointer;
    background-color: rgba(96, 169, 255, 0.2);
    transition: background 0.2s, border 0.2s;
}

.sslm__item--selected {
    cursor: pointer;
    background-color: rgba(96, 169, 255, 0.2);
    transition: background 0.2s, border 0.2s;
}

.sslm__item__actions {
    position: relative;
    display: none;
    justify-self: flex-end;
    margin-left: 10px;
    margin-right: 5px;
}

.sslm__item__link:hover + .sslm__item__actions,
.sslm__item:hover > .sslm__item__actions,
.sslm__item__actions:hover {
    display: block;
}

.sslm__item--none {
    display: flex;
    flex-direction: column;
}

.sslm__item--none:hover {
    cursor: auto;
    background-color: #fff;
}

.sslm__item__link__icon {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 4px;
}

.sslm__item__link__icon svg {
    position: relative;
    top: 2px;
}

.sslm__item__link__name {
    margin-right: 10px;
    width: 100px;
    max-width: 100px;
    min-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sslm__item__link__long-url {
    width: 180px;
    font-size: 12px;
    opacity: 0.6;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sslm__item__img {
    width: 150px;
}