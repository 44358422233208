.input {
	margin-bottom: 15px;
}

.input--password {
	position: relative;
}

.input__toggle {
	position: absolute;
	top: 12px;
	right: 12px;
}

.input--full-width {
	width: 100%;
}

.input__label {
	margin-bottom: 3px;
	margin-left: 2px;
	font-weight: 900;
	font-size: 12px;
}

.input__label__required {
	color: rgba(255, 99, 132, 1);
}

.input__description {
	font-style: italic;
	font-size: 12px;
}

.input__input {
	border: 1px solid #e5e6e7;
	border-radius: 8px;
	font-size: 14px;
	height: 40px;
	padding: 6px 12px;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	width: 100%;
	line-height: 1.4;
}

.input__input:focus {
	border-color: #60a9ff;
}

.input__input:disabled {
	cursor: not-allowed;
	background-color: #eee;
	opacity: 1;
}

.input__input::placeholder {
	font-size: 14px !important;
	color: #b0b6b9;
	opacity: 1;
}

.input__input--textarea {
	padding-top: 10px;
	resize: none;
	height: 100px;
}

.input__input--invalid,
.input__input--invalid:focus {
	border: 1px solid rgba(255, 99, 132, 1);
}

.input__input[type="file"] {
	display: none;
}

.input__file-input-btn {
	border: 1px solid #ccc;
	border-radius: 8px;
	padding: 8px 12px;
	font-weight: 300;

	display: flex;
	align-items: center;
	justify-content: center;
}

.input__file-input-btn:hover {
	border-color: #60a9ff;
	cursor: pointer;
}

.input__file-input-btn svg {
	margin-right: 8px;
}

.input__file-input-btn--disabled {
	cursor: not-allowed;
	background-color: #eee;
	opacity: 1;
}

.input__file-input-btn--disabled:hover {
	cursor: not-allowed;
	border: 1px solid #ccc;
	border-radius: 8px;
}
