.ecm {
	min-width: 800px;
	min-height: 534px;

	display: flex;
	flex-flow: column nowrap;
}

.ecm--centre-content {
	align-items: center;
	justify-content: center;
}

.ecm__loading {
	align-self: flex-start;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	height: 534px;
	overflow: hidden;
}

.ecm__actions {
	margin-top: auto;
	margin-right: 15px;
	align-self: flex-end;

	display: flex;
	flex-flow: row nowrap;
}

.ecm__input {
	border: 1px solid #e5e6e7;
	border-radius: 8px;
	height: 40px;
	font-size: 14px;
	padding-left: 10px;
	width: 230px;
}

.ecm__input--uneditable:hover {
	cursor: default;
}

.ecm__select {
	width: 230px;
}

.ecm__select--uneditable:hover {
	cursor: default;
}

.ecm__label {
	margin-top: 20px;
	margin-bottom: 5px;
	margin-left: 3px;
}

.ecm__label--phone,
.ecm__label--email {
	display: flex;
	align-items: center;
}

.ecm__label__favorite {
	margin-left: 4px;
	position: relative;
	top: 1px;
	color: #333333;
}

.ecm__label__favorite:hover {
	cursor: pointer;
	opacity: 0.6;
}

.ecm__label__favorite--enabled {
	color: #60a9ff;
	fill: #60a9ff;
}

.ecm__label__favorite--hidden {
	display: none;
}

.ecm__label__favorite--disabled {
	cursor: not-allowed;
	opacity: 0.6;
}

.ecm__label__favorite--disabled:hover {
	cursor: not-allowed;
}

.ecm__label__edit {
	width: 16px;
	height: 16px;
	margin-left: 6px;
	position: relative;
}

.ecm__label__plus {
	position: relative;
	top: 3px;
	left: 5px;
	width: 18px;
	height: 18px;
}

.ecm__label__plus:hover,
.ecm__label__edit:hover {
	cursor: pointer;
	opacity: 0.6;
}

.ecm__title {
	margin-top: 20px;
	margin-bottom: 0;
	margin-left: 22px;
	font-size: 16px;
	font-weight: 900;
}

.ecm__details {
	width: 100%;
	padding: 5px 20px;

	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.ecm__details__item {
	width: 230px;
	margin-right: 20px;
	position: relative;
}

.ecm__details__item__input-error {
	color: #ed5565;
}

.ecm__details__item__input-valid {
	color: #18a689;
}

.ecm__details__item__input-error,
.ecm__details__item__input-valid {
	margin-top: 0px;
	height: 20px;
	position: absolute;
	font-size: 12px;
}

.ecm__details__item__input-error__icon,
.ecm__details__item__input-valid__icon {
	margin-right: 6px;
	width: 15px;
	height: 15px;
}

.ecm__action-rows {
	width: 100%;
	flex: 1;
	padding: 5px 20px;

	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: flex-start;
}

.ecm__action-rows__row {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.ecm__action-rows__row__text {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	max-width: 610px;
}

.ecm__action-rows__row__toggle {
	margin-left: auto;
}

.ecm__action-rows__row__button {
	margin-left: auto;
}

.ecm__action-rows__row__title {
	font-size: 16px;
	font-weight: 900;
}

.ecm__action-rows__row__subtitle {
	margin-top: 10px;
	font-size: 14px;
}

.ecm__action-rows__spacer {
	width: 95%;
	height: 1px;
	background-color: #e4e9f0;
	margin-top: 25px;
	margin-bottom: 25px;
	align-self: center;
}

.ecm__save {
	margin-top: auto;
	margin-right: 15px;
	align-self: flex-end;
	display: flex;
	flex-flow: row nowrap;
}

.ecm__notes {
	font-size: 15px;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}

.ecm__notes__row {
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	display: flex;
}

.ecm__notes__line {
	width: 1px;
	height: 24px;
	margin-top: 18px;
	margin-bottom: 16px;
	border: dashed 1px #b2c7eb;
}

.ecm__notes__bubble {
	padding-top: 24px;
	padding-bottom: 24px;
	padding-left: 24px;
	padding-right: 61px;

	width: 450px;

	border-radius: 8px;
	border: solid 1px #b2c7eb;
	position: relative;
}

.ecm__notes__author {
	font-size: 12px;
	color: #9a9a9a;
	margin-bottom: 6px;
	align-self: flex-start;
}

.ecm__notes__message {
	font-size: 15px;
	color: #333333;
	white-space: pre-line;
}

.ecm__notes__quote {
	padding-top: 4px;
	padding-bottom: 2px;
	color: #7db3f2;
	font-family: ArialRounded;
	line-height: 0px;

	font-size: 57px;
	position: absolute;
	right: 10px;
	top: 28px;
}

.ecm__notes__bottom {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	margin-top: 6px;
}

.ecm__notes__control {
	padding-right: 5px;
	opacity: 0;
	cursor: pointer;
}

.ecm__notes__row:hover .ecm__notes__control {
	opacity: 1;
}

.ecm__notes__bottom__spacer {
	margin-left: auto;
}

.ecm__notes__date {
	font-size: 12px;
	color: #9a9a9a;
	align-self: flex-end;
}

/* Silly EI11 fixes */
@media all and (-ms-high-contrast: none) {
	.ecm__details {
		display: block;
	}

	.ecm__receive ~ .ecm__save {
		margin-top: 400px;
	}
}
