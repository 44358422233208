.company-selector {
    height: 20px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    z-index: 1900;
    position: relative;

    border-right: 1px solid #3333334a;
    padding-right: 20px;
}

.company-selector__icon {
    padding-right: 6px;
    padding-top: 3px;
    position: relative;
}

.company-selector__icon:hover {
    opacity: 0.8;
    cursor: pointer;
}

.company-selector__name {
    max-width: 280px;

    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.company-selector__name:hover {
    opacity: 0.8;
    cursor: pointer;
}

@media (max-width: 767px) {
    .company-selector__name {
        display: none;
    }
}

.company-selector__item {
    width: 100%;
    height: 40px;
    font-size: 16px;
    padding: 0 15px 0 15px;
    margin-bottom: 5px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
}

.company-selector__item__name {
    margin-top: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.company-selector__item:hover {
    cursor: pointer;
    background-color: rgba(96, 169, 255, 0.2);
    transition: background 0.2s, border 0.2s;
}

.company-selector__modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    position: absolute;
    top: 30px;
    right: -15px;

    width: 500px;
    height: 600px;
    min-height: 600px;
    background-color: white;
    border-radius: 5px;
    padding: 15px 0 15px 0;
    overflow: auto;
    box-shadow: 0 1px 5px rgb(0 0 0 / 15%);

    z-index: 5000;    
}


.company-selector__modal__loading {
    align-self: flex-start;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
}

.company-selector__modal__header {
    font-size: 22px;
    margin: 15px 0px 10px 0px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.company-selector__modal__search {
    width: 100%;
    padding: 0 15px 0 15px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.company-selector__modal__list {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding: 0px 10px 0px 10px;
    position: relative;
}

.company-selector__modal__list__header {
    font-weight: 900;
    color: #676a6c;
    margin: 10px 10px 10px 10px;
}

.company-selector__modal__list__header__action {
    float: right;
    margin-left: 15px;
}

.company-selector__modal__list__header__action-hidden {

}

.company-selector__modal__list__header__action:hover {
    cursor: pointer;
    opacity: 1;
}

.company-selector__modal__list__header__action-list {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-end;
	
	margin-left: 10px;
	margin-bottom: 20px;

}

.company-selector__modal__list__divider {
    background-color: #e4e9f0;
    height: 2px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.company-selector__modal__list__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.company-selector__modal__list__item:hover {
    background-color: #f6f6f9;
    cursor: pointer;
    transition: background 0.2s;
}

.company-selector__modal__list__item--highlight {
    background-color: #fffbe77d !important;
}

.company-selector__modal__list__item--selected {
    border-left: 2px solid #60a9ff;
    background-color: #f6f6f9;
}

.company-selector__modal__list__item__content {
    text-align: left;
    padding: 12px 12px 15px 12px;
    width: 100%;
    overflow: hidden;
}

.company-selector__modal__list__item__content__top {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 1px;
}

.company-selector__modal__list__item__content__name__text {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: flex-start;
}

.company-selector__modal__list__item__content__name__recent_text {
    display: inline-block;
    font-size: 9px;
    background-color: #f1f2f6;
    padding: 3px 7px 2px 7px;
    border-radius: 20px;
    color: #676a6c;
    margin-left: 7px;
}

.company-selector__modal__list__item__content__name__icon {
    opacity: 0.6;
    margin-left: 10px;
    margin-top: 2px;
}

.company-selector__modal__list__item__content__text {
    font-size: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.company-selector__modal__list__item__content__bottom mark {
    padding: 0px;
}

.company-selector__modal__list__item__content__locations{
    font-size: 11px;
    padding-top: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.company-selector__modal__list__item__content__locations__item
{
    background-color: #e6e6e6;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 22px;
    margin-right: 2px;
}

.company-selector__modal__list__item__content__locations__item:hover{
    background-color: #c8e1ff;;
}

.company-selector__modal__list__item__content__locations__item:hover mark{
    background: #c8e1ff;
    background-color: #c8e1ff;
}

.company-selector__modal__list__item__content__colour{
    width: 12px;
    min-width: 12px;
    height: 12px;

    margin-left: 7px;
    border-radius: 50%;
    margin-bottom: 2px;
}

.company-selector__modal__list__image__placeholder{
    width: 100%;
    max-width: 100%;

    padding-left: 60px;
    padding-right: 60px;

    position: absolute;
    bottom: 20px;

    align-self: center;
    vertical-align: middle;
}

.company-selector__modal__list__image__placeholder:hover {
    cursor: pointer;
}

.company-selector__modal__list__header__action-list--fade {
    opacity: 0.4;
}