.dh-header {
	padding: 20px 40px 15px 40px;
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
}

.dh-header-title {
	font-size: 36px;
}

.dh-header__back-btn {
	margin-right: 15px;
	color: #555;
}

.dh-header__back-btn:hover {
	cursor: pointer;
	opacity: 0.7;
}

.dh-header__back-btn svg {
	color: #555;
	width: 34px;
	height: 34px;
}

.dh-header__disclaimer {
	margin-left: 20px;
	align-self: center;
	color: #60a9ff;
	font-style: italic;
	font-size: 16px;
}

.dh-header__spacer {
	margin-left: auto;
}
