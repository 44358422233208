/* fms = feature matrix summary */

.fms {
	padding-left: 10px;
}

.fms__section-header:first-child {
	margin-top: 0;
}

.fms__section-header {
	font-size: 32px;
	margin-top: 30px;
	margin-left: 20px;
}

.fms__cards {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
}

.fms__cards__card {
	flex: none;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 250px;
	height: 150px;
	position: relative;
}

.fms__cards__card__number {
	font-size: 32px;
	line-height: 1;
}

.fms__cards__card__feature {
	font-size: 16px;
	line-height: 1.6;
	text-align: left;
	word-break: break-word;
}

.fms__cards__card__percentage {
	opacity: 0.6;
	font-size: 14px;
}
