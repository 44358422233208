.dh-card {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;

	padding: 15px 20px;
	border: 1px solid #e5e6e7;
	border-radius: 8px;
	background-color: white;
	margin-bottom: 25px;
}

.dh-card > div {
	width: 100%;
}

.dh-card__header {
	font-size: 19px;
	margin-bottom: 15px;
}
