/* ulnm = update location tasks modal */

.ultm__textarea {
	width: 100%;
	margin-bottom: 20px;
}

.ultm__task_title{
    margin-bottom: 20px;
}

.ultm__associated_message {
    border: 1px solid #E6E6E6;
    border-left-width: 5px;
    width: 100%;
    margin-right: 20px;
    border-radius: 8px;
    padding: 8px 10px 5px 10px;
    font-size: 11px;
	margin-bottom: 20px;
}

.ultm__associated_message__name {
    font-weight: 900;
}

.ultm__associated_message__content {
    margin-bottom: 0;
    width: 100%;
    word-break: break-word;
}