.Analytics__nps--padding {
	padding: 15px;
}

.Analytics__nps--progress-label {
	text-align: right;
}

.Analytics__nps--progress-label h2 {
	margin: 0;
}

.Analytics__nps--breakdown {
	display: flex;
	align-items: center;
}

.Analytics__nps--breakdown:not(:first-child) {
	margin-top: 15px;
}

.Analytics__nps--breakdown .progress {
	margin: -6px 0 0 0;
	height: 25px;
	padding: "0";
}

.Analytics__nps--promoters {
	background-color: #5bb85b !important;
}

.Analytics__nps--detractors {
	background-color: #d9534f !important;
}

.Analytics__nps--passives {
	background-color: #f1ad4e !important;
}

.Analytics__nps--promoters strong {
	color: #5bb85b !important;
	background-color: #ffffff;
}

.Analytics__nps--detractors strong {
	color: #d9534f !important;
	background-color: #ffffff;
}

.Analytics__nps--passives strong {
	color: #f1ad4e !important;
	background-color: #ffffff;
}
