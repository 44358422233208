
.mb-message-team-result-item {
    width: calc(100% - 40px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    margin: 5px 20px 5px 20px;
    border-radius: 8px;
    border: 1px solid white;

}

.mb-message-team-result-item:hover {
    background-color: rgba(96, 169, 255, 0.2);
    border: 1px solid #60A9FF;
	cursor: pointer;
	transition: background 0.2s, border 0.2s;
}

.mb-message-team-result {
    font-size: 15px;
    width: 100%;
    margin-right: 30px;
    min-height: 32px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 30px 0px 30px;
    z-index: 1000;
}

.mb-message-team-result-set {
    display: flex;
    flex-flow: column nowrap;
}

.mb-message-team-result-content {
    padding-top: 7px;
    padding-bottom: 7px;
}

.mb-message-date {
    opacity: 0;
    margin-left: auto;
    margin-right: 10px;
    font-size: 12px;
}