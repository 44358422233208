.Insight__mentions {
	margin-bottom: 0px;
}

.Insight__mentions:hover {
	text-decoration: underline;
	cursor: pointer;
	user-select: none;
}
.highlight-keyword {
	text-decoration: bold;
	background-color: yellow;
}
