.business-hours__table {
	width: 520px;
}

.business-hours__table--wide {
	width: 720px;
}

.business-hours__table--borderless {
	border: none !important;
}
.business-hours__table--responsive {
	overflow-x: auto;
}
.business-hours__table--tdPadding {
	padding-top: 13px !important;
}
.business-hours__table--closedLabel {
	position: relative;
	top: -8px;
	right: 3px;
}

.business-hours__table__input {
	width: 120px;
	border-radius: 5px;
}

.business-hours__table__input:focus {
	border-color: #60a9ff;
}

.business-hours__table__delete {
	margin-top: 8px;
}

.business-hours__table__delete:hover {
	cursor: pointer;
	opacity: 0.7;
}

.business-hours__table__delete svg {
	height: 20px;
	width: 20px;
}

.business-hours__box {
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 15px;
}

.business-hours__add-special-hours {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 10px;
	font-size: 14px;
	border: 2px dashed rgba(0, 0, 0, 0.1);
	stroke-width: 1;
}

.business-hours__add-special-hours:hover {
	cursor: pointer;
	border: 2px dashed rgba(0, 0, 0, 0.3);
	background-color: rgba(96, 169, 255, 0.1);
}

.business-hours__save {
	margin-top: 25px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.business-hours__description {
	padding: 8px;
	max-width: 510px;
}

.special-hours__container,
.business-hours__container {
	margin-left: 30px;
}

.business-hours__table__add-hours {
	cursor: pointer;
	display: inline-block;
	margin-bottom: 15px;
	margin-left: 84px;
}

.special-hours__table__name__input {
	width: 120px;
	border-radius: 5px;
}

.business-hours__table__timingRow {
	text-align: left;
}

.business-hours__table__timingRow__timing {
	display: inline-block;
	margin-bottom: 15px;
}

.business-hours__table__timingRow__divider {
	display: inline-block;
	margin-left: 10px;
	margin-right: 10px;
}

.business-hours__table__timingRow__delete {
	display: inline-block;
	vertical-align: middle;
	margin-left: 5px;
	opacity: 0.7;
}

.business-hours__table__timingRow__delete:hover {
	cursor: pointer;
}
