.menu-option {
	width: 100%;
	height: 40px;
	font-size: 15px;
	margin-bottom: 5px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
}

.menu-option:hover {
	cursor: pointer;
	background-color: rgba(96, 169, 255, 0.2);
	transition: background 0.2s, border 0.2s;
}

.menu-option__icon {
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 4px;
}

.menu-option__title {
	margin-top: 2px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
