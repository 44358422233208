.mb-message-list {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 30px;
}

.mb-message-list-loader {
    margin-top: 30px;
    margin-left: 30px;
    width: 70%;
}

.mb-message-list-loader > svg {
    margin-bottom: 20px
}

.mb-message-list-divider {
    text-align: center;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.mb-message-list-divider-line {
    border-bottom: 1px solid #E6E6E6;
    flex-grow: 1;
    margin: 0px 10px;
}

.mb-message-list-divider-date {
    text-transform: uppercase;
    color: #747d8c;
}

.mb-message-list-divider-line--danger {
    border-color: #ec4758;
    opacity: 0.5;
}

.mb-message-list-divider-date--danger {
    color: #ec4758;
    opacity: 0.5;
}

.mb-message-list-load-more-button {
    align-self: center;
    margin-left: 10px;
    font-size: 14px;
    padding: 6px 15px 4px 15px;
    border-radius: 50px;
    border: 2px solid #60A9FF;
    margin-bottom: 30px;
}

.mb-message-list-load-more-button:hover {
    border: 2px solid #60A9FF;
    background-color: #60A9FF;
    color: white;
    cursor: pointer;
}

.mb-message-list-bottom {
    
}

.mb-message-list-name {
    margin-top: 15px;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 15px;
    font-weight: 900;
}

.mb-message-list-date {
    font-weight: 100;
    padding-left: 10px;
    font-size: 14px;
}