.lagging-automations__last-msg-sent {
	margin-top: 15px;
}

.lagging-automations__last-msg-sent__counts {
	display: flex;
	margin-top: 10px;
}

.lagging-automations__last-msg-sent__counts .demandhub__status-lights--box {
	margin-right: 5px;
}

.lagging-automations__last-msg-sent__counts__count {
	margin-right: 10px;
}
