.api-keys-modal__domain,
.api-keys-modal__domain__item__input {
	width: 100%;
}

.api-keys-modal__domain {
	margin-top: 0px;
}

.api-keys-modal__domain__item__btn,
.api-keys-modal__domain__item__input {
	margin-top: 3px;
}

.api-keys-modal__domain__item__add-to-hosts {
	white-space: nowrap;
}
