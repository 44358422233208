.help-menu__trigger {
	margin-right: 20px;
	padding-top: 3px;
	color: #333;
	position: relative;
}

.help-menu__trigger:hover {
	cursor: pointer;
}
