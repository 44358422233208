input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.payments {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    /* justify-content: center; */
}

.payments-welcome-image {
    width: 300px;
    margin-left: 50px;
}

.payments-welcome-image > img {
    width: 100%;
}

.payments-container {
    margin-left: 50px;
}

.payments-container-title {
    font-size: 48px;
}

.payments-container-content {
    font-size: 18px;
}

.payments-container-action {
    margin-top: 30px;
    padding-top: 2px;
    height: 40px;
    width: 130px;
    font-size: 16px;
    border-radius: 8px;
    color: white;
    background-color: #60A9FF;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.payments-container-action:hover {
    cursor: pointer;
    opacity: 0.8;
}

.payments-container-error {
    margin-top: 20px;
    color: #ec4758;
    font-size: 14px;
}