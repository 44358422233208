.ProfilePicture__container {
	display: flex;
	justify-content: center;
}
.profile-pic-initials {
	font-weight: 900;
	padding-top: 12px;
}
.profile-pic {
	width: 100%;
	height: 100%;
	display: block;
}
.ProfilePicture__Dropdown--container > .open > .dropdown-toggle.btn-default,
.btn-default:focus,
.btn-default:hover {
	background-color: unset !important;
	box-shadow: unset !important;
}
.ProfilePicture__Dropdown--button {
	border: none !important;
	outline: none !important;
	border-radius: 1000px;
	padding: 0px;
}
.file-upload {
	display: none !important;
}
.circle {
	width: 202px;
	height: 202px;

	font-size: 70px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;

	border: 5px solid rgba(255, 255, 255, 0.7) !important;
	box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.12);
	border-radius: 1000px !important;

	overflow: hidden;
	margin-bottom: 65px;
	background-color: #f2f2ff;
}
.p-image {
	position: relative;
	right: 51px;
	top: 169px;
	color: #666666;
	transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.p-image:hover {
	transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.upload-button {
	font-size: 1.2em;
	transform: scale(1.5);
	cursor: pointer !important;
	border-radius: 50%;
	background-color: white;
	padding: 6px;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
}

.upload-button:hover {
	transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	color: #999;
}
