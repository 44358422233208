.reply-suggestions {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    
    margin: 10px 0px 180px 30px;
    
    overflow: auto;
    
    width: 70%;
    height: 40px;
    
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.reply-suggestions__heading {
    width: 100%;
    
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    
    font-size: 15px;
    font-weight: 900;
}

.reply-suggestions__heading__close {
    margin-left: auto;
}

.reply-suggestions__content {
    width: 600px;
    
    display: flex;
    flex-direction: row wrap;
    align-items: center;
    justify-content: flex-start;
}

.reply-suggestions__content__item {
    background-color: white;
    
    border: 1px solid #60a9ff;
    color: #60a9ff;
    
    padding: 7px 10px 5px 10px;
    border-radius: 10px;
    
    margin-right: 10px;
    
    max-width: 30%;
    height: 32px;
    
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.reply-suggestions__content__item:hover {
    color: white;
    background-color: #60A9FF;
    cursor: pointer;
    transition: background 0.2s, border 0.2s, color 0.2s;
}

.reply-suggestions__content__action {
    margin-right: 10px;
    border: 1px solid #60a9ff;
    color: #60a9ff;
    background-color: white;
    border-radius: 10px;
    height: 32px;
    min-width: 32px;
    
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.reply-suggestions__content__action:hover {
    color: white;
    background-color: #60A9FF;
    cursor: pointer;
    transition: background 0.2s, border 0.2s, color 0.2s;
}

