@font-face {
	font-family: demandhub-montserrat;
	src: url("https://cdn.demandhub.co/lib/icons/Montserrat-Regular.ttf");
}
.widget-config__top-header {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0 25px;
	width: 100%;
}

.widget-config__Dropdown--container {
	margin-right: 10px;
}

.widget-config__Dropdown--container ul {
	left: auto;
}
.widget-config__Dropdown--container ul li a:focus {
	outline: none;
}
.widget-config__Dropdown--container div button {
	background-color: white !important;
}

.widget-config__Dropdown--container ul li a div:focus {
	outline: none;
}

.widget-config__panel-body--div {
	margin-top: -30px;
	padding-left: 25px;
	padding-right: 25px;
}

.widget-config__menu-item--div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: default;
	padding: 10px 0;
}
.widget-config__widget--container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	flex-direction: row-reverse;
	overflow: hidden;
	transition: max-height 0.4s ease 0s;
	padding-right: 25px;
	padding-left: 25px;
	padding-bottom: 50px;
}
.widget-config__widget-tooltip--container {
	right: 10px;
	width: 290px;
	max-width: 355px;
	height: 80px;
	background: #fff;
	border-radius: 15px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	z-index: 0;
	margin-left: auto;
}
.widget-config__widget-tooltip--content {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	height: 78px;
	width: 300px;
	padding-left: 10px;
	padding-right: 10px;
}
.widget-config__widget-tooltip--avatar {
	height: 50px;
	width: 50px;
}
.widget-config__widget-tooltip--content::before {
	content: "";
	height: 40px;
	width: 40px;
	background-color: rgb(255, 255, 255);
	position: absolute;
	transform: rotate(45deg);
	bottom: 106px;
	right: 69px;
	border-radius: 5px;
	z-index: -1;
}
.widget-config__widget-tooltip--avatar > img {
	border-radius: 50px !important;
	height: 50px !important;
	width: 50px !important;
	max-width: initial !important;
	cursor: pointer;
}
.widget-config__widget-tooltip--message {
	font-size: 16px;
	color: #404040;
	margin-left: 10px;
	line-height: 1.4 !important;
	font-weight: 600;
}
.widget-config__widget-icon {
	border-radius: 40px;
	width: 60px;
	height: 60px;
	position: absolute;
	top: 190px;
	right: 58px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.widget-config__widget-settings--container {
	width: 54%;
	min-width: 440px;
}
.widget-config__widget-settings--item {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
	width: 100%;
	font-size: 14px;
	color: rgb(45, 47, 49);
	min-height: 120px;
	padding: 36px;
}
.widget-config__widget-settings--label {
	font-weight: 600;
	width: 35%;
}
.widget-config__widget-settings--input-div {
	width: 60%;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
}
.widget-config__widget-settings--input-wrapper {
	width: 260px;
}
.widget-config__widget-settings--textarea {
	width: 100%;
	display: block;
	max-height: 75px;
	resize: none;
	padding: 10px 16px;
	border-radius: 3px;
	border-width: 1px;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.widget-config__widget-settings--textarea:focus {
	border-width: 1px;
	border-style: solid;
	border-color: #1ab394;
	border-image: initial;
	outline: none;
}
.widget-config__widget-settings--input-image {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
}
.widget-config__widget-settings--edit-image {
	padding-left: 24px;
}
.widget-config__widget-settings--edit-image-button {
	display: flex;
	align-items: center;
}
.widget-config__widget-settings--input-no-image {
	height: 50px;
	background-color: lightgray;
	width: 50px;
	border-radius: 50px;
}

#widget-config__modal--container {
	display: flex !important;
	max-width: 440px;
	margin: auto;
	align-items: flex-start;
	max-height: 90vh;
	justify-content: center;
}

.widget-config__modal__body {
	display: flex;
	justify-content: center;
	text-align: center;
	flex-flow: column wrap;
	align-items: center;
	width: 100%;
}

.widget-config__modal__body-avatars {
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	padding: 0 0 30px 0;
	width: 100%;
}

.widget-config__widget-tooltip--avatar-gallery {
	display: flex;
	align-items: center;
	margin: 3px;
	text-align: left;
}
.widget-config__widget-tooltip--avatar-small {
	cursor: pointer;
	padding: 10px 4px;
	border-radius: 5px;
}
.widget-config__widget-tooltip--avatar-small:hover {
	background-color: aliceblue;
}
.widget-config__widget-tooltip--avatar-clicked {
	background-color: aliceblue;
}
.widget-config__widget-tooltip--avatar-small > img {
	border-radius: 50px;
	height: 40px;
	width: 40px;
	cursor: pointer;
}
.widget-config__user--name {
	font-size: 16px;
	margin-left: 10px;
	max-width: 65%;
}
.widget-config__gallery--check {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 54px;
}
.widget-config__embedded-widget--textarea {
	min-height: 0px;
}
.widget-config__panel {
	margin-bottom: 30px;
	border-radius: 10px;
}
.widget-config__panel--title {
	display: flex;
	justify-content: space-between;
	padding: 40px;
}
.widget-config__panel--options-button {
	display: flex;
	align-items: center;
}
.widget-config__status--indicator {
	font-size: 15px;
}
.widget-config__widget--preview-margin {
	margin-left: 8px;
}
.widget-config__widget--edit-image-margin {
	margin-left: 8px;
}
.widget-config__widget--action-buttons {
	margin-bottom: 30px;
	padding-right: 25px;
	padding-left: 25px;
}
.widget-config__widget--list {
	padding-bottom: 60px;
	padding-top: 60px;
}
.widget-config__widget--save-bar-container {
	position: absolute;
	width: calc(100% - 1px);
	display: flex;
	justify-content: flex-end;
	height: 80px;
	padding-right: 62px;
	align-items: center;
	z-index: 100;
	-webkit-transform: translate(-14px, -71px);
	transform: translate(-14px, -71px);
	border-top: 1px solid rgb(228, 233, 240);
	background: white;
}
.widget-config__widget--save-bar-cancel {
	margin-left: 10px;
}
.widget-config__booking--refresh {
	margin-top: 3px;
	cursor: pointer;
	user-select: none;
}
.widget-config__booking--create {
	margin-top: 2px;
	cursor: pointer;
	user-select: none;
}

.widget-config__tooltip-image--no-pictures-container {
	padding: 40px 0 70px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column wrap;
}
.widget-config__tooltip-image--no-pictures-info {
	width: 326px;
}
.widget-config__form--item {
	display: flex;
	align-items: center;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-bottom: 15px;
}
.widget-config__booking--options-icon {
	user-select: none;
	cursor: pointer;
}
.widget-config__booking--header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
}
.widget-config__booking--container {
	padding-right: 25px;
	padding-left: 25px;
}

@media screen and (min-width: 1268px) {
	.widget-config__Dropdown--container ul {
		left: auto;
	}
}

/* wc == widget-config */
/* General styles for the widget configurations */

.wc {
	display: flex;
	flex-flow: row wrap-reverse;
	align-items: flex-end;
	justify-content: flex-start;

	margin: 20px 30px;
	width: calc(100% - 60px);
	min-width: 400px;
}

.wc__options {
	margin-right: 50px;
	max-width: 500px;
	min-width: 400px;
}

.wc__options__field {
	font-size: 14px;
	height: 120px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	border-bottom: 1px solid #f5f5f5;
}

.wc__options__field--small {
	height: 75px;
}

.wc__options__field__label {
	flex: 1;
	margin-right: 20px;
}

.wc__options__field__label__title {
	font-weight: 900;
	display: flex;
}

.wc__options__field__label__title__small {
	font-weight: 300;
	margin-left: 5px;
}

.wc__options__field__label__description {
	margin-top: 5px;
}

.wc__options__field__input {
	width: 40%;
	margin-left: auto;

	display: flex;
	flex-flow: column nowrap;
	align-items: flex-end;
}

.wc__options__field__input {
	margin-left: auto;

	display: flex;
	flex-flow: column nowrap;
	align-items: flex-end;
}

.wc__options__field__actions {
	display: flex;
	align-items: center;
	position: relative;
}

.wc__options__field__actions svg {
	position: relative;
	top: 2px;
}

.wc__options__field__btn:hover {
	cursor: pointer;
	opacity: 0.7;
}

.wc__options__field__input__select {
	width: 100%;
}
