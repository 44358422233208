.tasks{
    margin-left: 30px;
    margin-right: 30px;
}

.tasks__tasks-container{
    display: flex;
    flex-flow: row wrap;
}

.tasks__search {
	margin: 0 30px 30px 30px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
}

.tasks__info{
    display: flex;
    position: absolute;
    bottom: 20px;
    right: 20px;

    width: 35px;
    height: 35px;

    padding-top: 3px;

    border-radius: 50px;
    overflow-y: auto;

    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    font-weight: 900;
    color: #5a5a5a;

    background-color: #f6f6f9;
    border: 1px solid rgba(0, 0, 0, 0);

}

.tasks__info__tooltip-text {
    width: 100%;
    white-space: pre-line;
}

.tasks__landing-page-container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tasks__landing-page{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}


.tasks__landing-page__image{
    width: 300px;
    margin-right: 30px;
}

.tasks__landing-page__image > img{
    width: 100%;
    min-width: 150px;
}

.tasks__landing-page__content{
    width: 600px;
    max-width: 600px;
}

.tasks__landing-page__title{
    font-size: 48px;
}

.tasks__landing-page__text-content{
    font-size: 18px;
    max-width: 660px;
}