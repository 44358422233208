.invites__label {
	display: inline-block;
	padding: 2.75px 5.5px;
	margin-bottom: 0;
	font-size: 12px;
	font-weight: 400;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 4px;
	min-width: 71px;
	color: #fff;
	text-transform: capitalize;
}

.invites__label--creating {
	background-color: #a6a6a6;
}

.invites__label--pending {
	background-color: #a6a6a6;
}

.invites__label--sending {
	background-color: #a6a6a6;
}

.invites__label--sent {
	background-color: #1780ff;
}

.invites__label--failed {
	background-color: #fb1900;
}

.invites__label--cancelled {
	background-color: #ffb660;
}

.invites__label--deleted {
	background-color: #a6a6a6;
}

.invites--pagination {
	padding-bottom: 10px;
}

.invites__container {
	display: flex;
	flex-wrap: nowrap;
	align-content: space-between;
	float: right;
	width: 50%;
	padding-top: 5px;
	padding-bottom: 5px;
}

.invites-table__header {
	width: 100%;
	float: left;
	margin-left: 20px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.invites__container--header {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.invites__container--header select {
	margin-left: auto;
	width: 300px;
}

.invites__container--header .filters {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 15px;
}

.invites__container--header .switch {
	margin-right: 8px;
	margin-left: 8px;
}

.invites__search-filter {
	width: 100%;
	margin-top: 10px;
	max-width: 400px;
}

.invites__invite-queue-message {
	position: absolute;
	top: 60px;
	right: 10px;
	display: flex;
	justify-content: center;

	text-align: center;
	font-size: 16px;
	font-weight: 300;
	padding: 8px 10px;
	border-radius: 10px;
	color: #31708f;
	background-color: #d9edf7;
	border-color: #bce8f1;
}

.invites__selector-and-filters {
	display: flex;
	flex-direction: column;
}

.invites_buttons {
	display: flex;
	flex-wrap: nowrap;
	align-content: space-between;
	margin-top: 20px;
	margin-left: 10px;
}

.invites--bulk-uploader {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.invites-file-uploader {
	display: flex;
	flex-direction: column;
}

.invites-bulkUploading-buttons {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.invites-bulkUploading-buttons button {
	width: auto;
}

.invites-bulkUploading-error-container {
	display: flex;
	flex-direction: column;
}

.invites-bulkUploading-error-message {
	align-self: center;
	color: red;
}

.invites-bulkUploading-preview {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
}

.invites-bulkUploading-header-info {
	z-index: 100000000;
}

.invites-bulkUploading-header-info > .tooltip-inner {
	max-width: 600px !important;
	padding: 10px 15px 10px 20px;
	background: #ffffff;
	color: rgb(0, 0, 0, 0.7);
	border: 1px solid #737373;
}

.invites-bulkUploading-header-info.tooltip.in {
	opacity: 1 !important;
}

.invites-bulkUploading-header-infographic {
	max-width: 500px;
}

.invites-bulkUploading-header-help-text {
	cursor: default;
}

.invites-bulkUploading-preview-rows {
	display: block;
	overflow: auto;
	margin-bottom: 0 !important;
}

.invites-bulkUploading-preview-row {
	align-self: flex-start;
	display: flex;
	flex-direction: row;
}

.invites-bulkUploading-header {
	margin-bottom: 5px;
	width: fit-content;
}

.invites-bulkUploading-header:hover {
	cursor: pointer;
	opacity: 0.6;
}

.invites-bulkUploading-header__text {
	margin-left: 5px;
	position: relative;
	top: 3px;
}

#invites-bulkUploading-csv-data-cell {
	min-height: 40px;
	border-style: groove;
	padding: 2px;
	border-width: 1px 1px;
	white-space: nowrap;
}

.invites--confirmUpload-button {
	width: 13%;
}

.invites--cancelUpload-button {
	width: 13%;
}

.invites-bulkinvites--heading {
	text-decoration: underline;
}

.invites__sendbulkinvites--button {
	width: 20%;
}

.invites__sendbulk__appointments--button {
	width: 23%;
}

.invites-bulkinvite-organizer {
	display: flex;
	flex-direction: column;
}

.invites-bulkinvite-status-image {
	max-width: 75px;
	max-height: 75px;
	align-self: center;
}

.invites-bulkinvite-status-container {
	display: flex;
	flex-direction: column;
}

.invites--bulkinvites--info {
	margin-bottom: 20px;
}

.invites--bulkinvites--info > ul {
	margin-left: -24px;
}

.invites-bulkinvites--importantText {
	font-weight: bold;
}

.invites__btn {
	padding: 2.75px 5.5px;
	border-radius: 4px;
}

#invites__react-table {
	margin-right: 195px;
}

.invites__search {
	padding: 0 30px;
	margin-bottom: 15px;
	width: 100%;
	display: flex;
	align-items: center;
}

.invites__search__actions {
	display: flex;
	align-items: center;
	position: relative;
}

.invites__search__actions div {
	margin-left: 10px;
	margin-right: 0;
}

.invites__search__actions .invites__invite-queue-message {
	position: relative;
	top: 3px;
}

.invites__filters {
	padding: 0 30px;
	margin-bottom: 15px;
	width: 100%;
	display: flex;
	align-items: center;
}

.invites__filters--options {
	margin-bottom: 0;
}

.invites__filters > div {
	margin-right: 20px;
}

.invites__filters__select {
	margin-top: 2px;
	width: 200px;
}

.invites__filters__select .select__label {
	margin-bottom: 0;
}

.invites__filters__search {
	margin-top: 8px;
}

.invites__filters__search .dh-search {
	min-width: 220px;
	width: 220px;
}

.invites__filters__actions {
	margin-left: auto;
	display: flex;
	align-items: center;
}

.invites__filters__datepicker {
	margin-bottom: 15px;
}

.invites__filters__datepicker input {
	height: 40px;
	max-width: 120px;
	border-radius: 8px;
}

.invites__filters__datepicker .common__datepicker__date-range__text {
	font-size: 12px;
}

.invites__table {
	padding-left: 30px;
	width: 100%;
}

@media (max-width: 1200px) {
	.invites__container--header {
		flex-direction: column;
	}

	.invites__search {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.invites__search__actions {
		flex-direction: row-reverse;
		margin-top: 15px;
	}

	.invites__search__actions .dh-action {
		position: relative;
		top: 0;
	}

	.invites__search__actions .invites__invite-queue-message {
		position: relative;
		top: -2px;
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.invites_buttons {
		margin-right: 60px;
	}
}

@media (max-width: 992px) {
	.invites__container--header {
		align-items: flex-start;
	}
}

@media (max-width: 786px) {
	#invites__react-table {
		margin-right: 0px;
	}

	#invites__react-table .row .col-lg-12 {
		padding-right: 0px;
	}
}

@media (max-width: 767px) {
	.invites__container__header {
		justify-content: space-between;
	}
}

.csv-mapper--instructions {
	margin: 0px 0 20px;
}

.csv-mapper--instructions__list {
	display: flex;
	flex-direction: column;
}

.csv-mapper--instructions__list > div {
	margin: 7px 0;
	padding-left: 8px;
	font-size: 13px;
	border-left: #c2c2c2 1px solid;
}

.csv-mapper-property-selector > div {
	background-color: aliceblue !important;
	min-width: 150px !important;
}

.csv-mapper--name-format-selector {
	width: 25%;
	margin: 10px 0;
}

.csv-mapper--name-format-selector-icon {
	position: relative;
	top: 3px;
}

.csv-mapper--utc-format-selector {
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
}

.csv-mapper--utc-format-selector__container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.csv-mapper--utc-format-selector__container__label {
	margin-left: 10px;
}

.csv-mapper--show-first-rows {
	margin-bottom: 10px;
}
