.Analytics__ibox-content {
	padding-top: 30px;
}
.AnalyticsSummary__ibox-content--margin {
	margin-bottom: 45px;
}
.LocationTable__ibox-content--margin {
	margin-bottom: 50px;
}
.Analytics__ibox-content__multilocation {
	padding-top: 20px;
}
.Analytics__counts {
	font-size: 16px;
}
.Analytics__graph--heading {
	padding-bottom: 50px;
}
.Analytics__graph--site {
	text-align: right;
}
.AnalyticsSummary__cards {
	padding: 35px;
}
.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
	background-color: #f0f8ff !important;
	color: #4a90e2;
}
.InputFromTo .DayPicker-Day {
	border-radius: 0 !important;
}
.InputFromTo input {
	background-color: #ffffff;
	background-image: none;
	border: 1px solid #e5e6e7;
	border-radius: 1px;
	color: inherit;
	display: block !important;
	height: 30px;
	padding: 6px 12px;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	width: 100%;
}
.InputFromTo input:focus {
	border-color: #1ab394;
	outline: none;
}
.InputFromTo input:focus {
	border-color: #1ab394;
	outline: none;
}
.InputFromTo .DayPicker-Day--start {
	border-top-left-radius: 50% !important;
	border-bottom-left-radius: 50% !important;
}
.InputFromTo .DayPicker-Day--end {
	border-top-right-radius: 50% !important;
	border-bottom-right-radius: 50% !important;
}
.InputFromTo .DayPickerInput-Overlay {
	width: 400px;
}

.InputDate .DayPickerInput-Overlay {
	width: auto;
	max-width: 190px;
}

.ReviewSites__update-date--btn {
	margin-left: 0.4em;
}

@media screen and (max-width: 783px) {
	.ReviewSitesAnalytics__form-datepicker {
		display: inline-block !important;
	}
	.ReviewSites__update-date--btn {
		margin-top: 0.5em;
		margin-left: 0em;
	}
}
@media screen and (max-width: 582px) {
	.InputFromTo .DayPickerInput-Overlay {
		width: 200px;
	}
	.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
		background-color: #f0f8ff !important;
	}
}

@media (max-width: 767px) {
	.ReviewSitesAnalytics__site--dropdown {
		margin-top: 0.5em;
		width: 160px;
	}
	.remove-ibox-margin {
		margin-bottom: -25px;
	}
	.Analytics__graph--site {
		float: left !important;
		width: 100%;
	}
	.LocationAnalytics__table--condensed {
		padding: 0px;
	}

	.AnalyticsSummary__cards {
		padding: 40px 30px 0px 30px;
		max-height: 200px;
		height: 200px;
	}

	.m-b-md {
		margin-bottom: 0px;
	}
}

.GmbLocationStats__metric-data-cards {
	margin-top: 15px;
	margin-bottom: 15px;
}

.GmbLocationStats__metric-data-card {
	font-size: 14px;
	padding: 15px;
	margin: 10px 0;

	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
	border: solid 1px #ecf0f1;
	border-radius: 8px;
	background-color: #ffffff;
}

.GmbLocationStats__metric-data-card .title {
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 10px;
}

.GmbLocationStats__metric-data-card .title span svg {
	position: relative;
	top: 3px;
}

.GmbLocationStats__metric-data-card .with-demandhub,
.GmbLocationStats__metric-data-card .before-demandhub,
.GmbLocationStats__metric-data-card .section-header {
	border-bottom: 1px solid rgba(52, 58, 75, 0.12);
	border-top: 1px solid rgba(52, 58, 75, 0.12);
	font-weight: 600;
	padding: 5px 0;
	margin: 10px 0;
}

.GmbLocationStats__metric-data-card .with-demandhub .hub-blue,
.GmbLocationStats__metric-data-card .before-demandhub .hub-blue {
	color: #118ace;
}

.GmbLocationStats__metric-data-card .gmb-stat {
	display: flex;
	justify-content: space-between;
}

.GmbLocationStats__metric-data-card .gmb-stat span {
	margin: 2px 0;
	width: 50%;
}

.GmbLocationStats__tooltip {
	width: 300px;
}

.GmbLocationStats__more-after {
	color: #60a9ff;
	text-align: center;
	font-size: 18px;
	margin: 45px;
}

.gmb-stat .statistic-number {
	font-size: 14px;
	font-weight: 600;
	text-align: right;
}

.gmb-stat .red {
	color: #d9534f;
}

.gmb-stat .yellow {
	color: #f1ac4e;
}

.gmb-stat .green {
	color: #5bb85a;
}

.gmb-stat span svg,
.before-demandhub span svg {
	position: relative;
	top: 2px;
}

.analytics__section__chart {
	background-color: #ffffff;
	width: 100%;
}

.analytics__section__chart__spinner {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 150px;
}

.analytics__section__chart--review-sites-results {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.analytics__ratings-by-location {
	height: 350px;
	padding-right: 15px;
	margin-bottom: 15px;
	overflow-x: hidden;
	overflow-y: auto;
}

.analytics__ratings-by-site,
.analytics__reviews-by-site {
	height: 375px;
	padding-right: 15px;
	overflow-x: hidden;
	overflow-y: auto;
}

.analytics-messenger {
	margin-bottom: 100px;
}

.reengagement-attribution {
	margin: 0 40px 50px;
	width: 95%;
	flex: 1;
	display: flex;
	justify-content: center;
}

.analytics-page__split {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.analytics-page__split__card {
	flex: 1;
}

.analytics-page__split__card:first-child {
	margin-right: 20px;
}

.location-analytics__split {
	margin-bottom: -20px;
}

@media (max-width: 1250px) {
	.analytics-page__split {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.analytics-page__split__card {
		flex: 1;
		width: 100%;
		margin-right: 0;
	}

	.analytics-page__split__card:first-child {
		margin-right: 0;
	}
}
