.dh-templates {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	width: 100%;
	height: 100%;

	overflow: hidden;
	background-color: #ffffff;
	border-radius: 5px;
}

.dh-templates__header {
	font-size: 22px;
	margin: 15px 0px 10px 0px;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
}

.dh-templates__header__title {
	margin-left: 15px;
}

.dh-templates__header__action {
	cursor: pointer;
	margin: 1px 5px 0px 10px;
}

.dh-templates__header__action:hover {
	opacity: 0.8;
}

.dh-templates__header__x {
	margin: 1px 15px 0px auto;
	cursor: pointer;
}

.dh-templates__header__x:hover {
	opacity: 0.8;
}

.dh-templates__search {
	width: 100%;
	padding: 0 15px 0 15px;
	margin-bottom: 15px;
}

.dh-templates__list {
	flex-grow: 1;
	overflow-y: auto;
	overflow-x: hidden;
	width: 100%;
	padding: 0px 10px 0px 10px;
}

.dh-templates__list__header {
	font-weight: 900;
	color: #676a6c;
	margin: 10px 10px 10px 10px;
}

.dh-templates__list__divider {
	background-color: #e4e9f0;
	height: 2px;
	width: 95%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	margin-bottom: 20px;
}

.dh-templates__list-loading {
	padding: 0 15px;
	width: 100%;
	height: 100%;
}

.dh-templates__list__item {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.dh-templates__list__item:hover {
	background-color: #f6f6f9;
	cursor: pointer;
	transition: background 0.2s;
}

.dh-templates__list__item--highlight {
	background-color: #fffbe77d !important;
}

.dh-templates__list__item--selected {
	border-left: 2px solid #60a9ff;
	background-color: #f6f6f9;
}

.dh-templates__list__item__content {
	text-align: left;
	padding: 12px 12px 15px 12px;
	width: 100%;
	overflow: hidden;
}

.dh-templates__list__item__content__top {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	align-items: flex-start;
	white-space: nowrap;
	overflow: hidden;
	margin-bottom: 1px;
}

.dh-templates__list__item__content__name__text {
	font-size: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
	align-self: flex-start;
}

.dh-templates__list__item__content__name__recent_text {
	display: inline-block;
	font-size: 9px;
	background-color: #f1f2f6;
	padding: 3px 7px 2px 7px;
	border-radius: 20px;
	color: #676a6c;
	margin-left: 7px;
}

.dh-templates__list__item__content__name__icon {
	opacity: 0.6;
	margin-left: 10px;
	margin-top: 2px;
}

.dh-templates__list__item__content__text {
	font-size: 12px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.dh-templates__list__item-actions {
	display: flex;
	flex-flow: row nowrap;
	margin: auto;
}

.dh-templates__list__item-action {
	padding-right: 15px;
}

.dh-templates__list__item-action:hover {
	opacity: 0.8;
}

.dh-mini-templates {
	position: absolute;
	top: -305px;
	left: 0;
	width: 440px;
	height: 300px;
	max-height: 300px;
	background-color: white;
	border-radius: 5px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

.dh-mini-templates--hidden {
	height: 0;
	opacity: 0;
	width: 0;
}
