.dh-select-container {
	display: flex;
	align-items: center;
	border-radius: 8px;
	border: 1px solid hsl(0, 0%, 80%);
	width: 100%;
	padding: 0;
}

.dh-select {
	width: 100%;
	font-size: 16px;
	color: #5a5a5a;
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	height: 40px;
	border-radius: 8px;
	border: 1px solid #e5e6e7;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	padding: 5px 25px 3px 10px;
	z-index: 1;
	background: transparent;
}

.dh-select-chevron {
	position: relative;
}

.dh-select-chevron svg {
	position: absolute;
	top: -7px;
	right: 5px;
	height: 16px;
	width: 16px;
}
