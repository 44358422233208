.side-modal {
	background-color: white;
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	display: flex;
	width: 325px;
	max-width: 325px;
	flex-direction: column;
	border-left: 1px solid rgba(0, 0, 0, 0.1);
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	overflow-y: auto;
	overflow-x: hidden;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	z-index: 2000;
}

.side-modal__body {
	width: 325px;
	max-width: 325px;
	height: 100%;
	padding-left: 25px;
	padding-right: 25px;
}

.side-modal__header-title {
	margin-top: 20px;
	margin-bottom: 15px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 21px;
	font-weight: 600;
	color: #333;
}

.side-modal__header-title div {
	margin-right: 10px;
}

.side-modal__header-title svg {
	width: 16px;
	height: 16px;
}

.side-modal__header {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.side-modal__header-x {
	margin-top: 10px;
	align-self: flex-end;
}

.side-modal__header-x svg {
	cursor: pointer;
	width: 18px;
	height: 18px;
}

.side-modal__header-x svg:hover {
	opacity: 0.6;
}

.side-modal__header-sub-title {
	font-size: 21px;
	font-weight: 600;
	color: #333;
}
