.mb-assigned-user {
    margin-right: 30px;
    padding-right: 30px;
    border-right: 1px solid #E6E6E6;
    height: 50px;
}

.mb-assigned-user-label {
    font-size: 10px;
    margin-bottom: -7px;
}

.mb-assigned-user-selector {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.mb-assigned-user-icon {
    padding-right: 6px;
    margin-top: 8px;
}

.mb-assigned-user-icon:hover {
    opacity: 0.8;
    cursor: pointer;
}

.mb-assigned-user-name {
    font-size: 18px;
}

.mb-assigned-user-name:hover {
    opacity: 0.8;
    cursor: pointer;
}


.mb-assigned-user-list {
    position: fixed;
    top: 100px;

    width: 300px;
    min-height: 300px;
    max-height: 500px;
    
    background-color: white;
    border-radius: 8px;
    padding: 10px 0 0 0px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    
    overflow: auto;
    
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    z-index: 9999999;
}

.mb-assigned-user-item {
    width: 100%;
    min-height: 40px;
    font-size: 16px;
    margin-bottom: 5px;
    padding: 0 15px 0 15px;
    margin-bottom: 5px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.mb-assigned-user-item:hover {
    cursor: pointer;
    background-color: rgba(96, 169, 255, 0.2);
    transition: background 0.2s, border 0.2s;
}

.mb-assigned-user-item__selected {
    margin-top: 3px;
    margin-left: auto;
}