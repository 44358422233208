.connections {
	display: flex;
	flex-flow: row wrap-reverse;
	align-items: flex-end;
	justify-content: flex-start;

	margin: 20px 30px;
	width: calc(100% - 60px);
	min-width: 400px;
}

.connections__inner {
	margin-right: 50px;
	max-width: 900px;
	width: 100%;
}

.connections__section {
	margin-bottom: 20px;
}

.connections__section-title {
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 15px;
}

.Connections__button {
	border-radius: 8px;
	padding: 10px;
	width: 100%;
	cursor: pointer;
}

.Connections__gmb--button {
	background-color: #4785ed;
	color: white;
}

.Connections__gmb--button:hover,
.Connections__fb--button:hover {
	box-shadow: 0 4px 17px 7px rgba(62, 68, 77, 0.1);
}

.Connections__fb--button {
	background-color: #395c98;
	color: white;
}

.Connections__connection-button:hover {
	box-shadow: 0 4px 17px 7px rgba(0, 192, 200, 0.1);
}

.Connections__connection-button {
	color: white;
}

.Connections__connection-button--janeapp {
	background-color: #00c1ca;
	color: white;
}

.Connections__connection-button--mindbody,
.Connections__connection-button--drchrono {
	background-color: #2d2d2d;
	color: white;
}

.Connections__connection-button--pteverywhere {
	background-color: #f47920;
	color: white;
}

.Connections__connection-button--simplepractice {
	background-color: #318fb4;
	color: white;
}

.Connections__connection-button--simplepractice {
	background-color: #318fb4;
	color: white;
}

.Connections__connection-button--athenahealth {
	background-color: #512c81;
	color: white;
}

.Connections__connection-button--salesforce {
	background-color: #1d9cd6;
	color: white;
}

.Connections__connection-button--booker {
	background-color: #1d9cd6;
	color: white;
}

.Connections__connection-button--clio {
	background-color: #0070df;
	color: white;
}

.Connections__connection-button--noterro {
	background-color: #0070df;
	color: white;
}

.Connections__connection-button--disconnect {
	background-color: #e74c3c;
	color: white;
}

.Connections__button:hover {
	color: white;
}

.Connections__button:active {
	color: white;
}

.Connections__button:visited {
	color: white;
}

.Connections-modal__input {
	width: 100%;
}

.Connections-modal__select {
	width: 100%;
	margin-bottom: 15px;
}

.connections__section-list {
	display: flex;
	flex-flow: column wrap;
	width: 100%;
}

.connections__section-list-item {
	display: flex;
	flex-flow: row nowrap;

	margin-top: 10px;
	margin-bottom: 10px;

	border: 1px solid #e0e0e0;

	padding: 14px;
	border-radius: 7px;
}

.connections__section-list-item__icon {
	padding: 10px;
	padding-right: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 100px;
	width: 100%;
}

.connections__section-list-item__icon > img {
	max-width: 100%;
	max-height: 50px;
	width: auto;
	height: auto;
	object-fit: contain; /* Maintain aspect ratio */
}

.connections__section-list-item__information {
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
}

.connections__section-list-item__spacer {
	margin-left: auto;
}

.connections__section-list-item__information_title {
	font-size: 16px;
	font-weight: 600;
}

.connections__section-list-item__information_description {
	font-size: 14px;
	padding-top: 5px;
}

.connections__section-list-item__status_indicator {
	margin-left: 15px;
	margin-right: 15px;

	display: flex;
	align-self: center;
}

.connections__section-list-item__status_indicator_circle {
	width: 17px;
	height: 17px;
	border-radius: 50%;
}

.connections__item__status--ok {
	background-color: green;
}

.connections__item__status--bad {
	background-color: red;
}

.connections__item__status--partial {
	background-color: #fdda0d;
}

.connections__section-list-item__action {
	flex-flow: row wrap;
	display: flex;
	align-self: center;
	min-width: 160px;
	justify-content: flex-end;
}

.connections__search-section {
	margin: 0 30px 30px 0px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
}
