#bulk-send-button {
	color: #60a9ff;
	width: 100%;
	margin-top: 10px;
}

#bulk-send-button:hover {
	cursor: pointer;
}

.send-review-invite-modal {
	min-width: 300px;
	width: 300px;
}

.send-review-invite-modal .modal__content__header {
	font-size: 23px;
	margin-bottom: 0;
}

.send-review-invite-modal .modal__field:first-child {
	margin-top: 0;
}

.send-review-invite-modal__text-bubble {
	width: 90%;
	margin-top: 7px;
}

.send-review-invite-modal__text-bubble > .talk-bubble {
	margin-top: 0;
}

.send-review-invite-modal__info {
	margin-bottom: -10px;
}

.send-review-invite-modal__info > svg {
	position: relative;
	top: 3px;
	color: #60a9ff;
}

#final-send-invite {
	margin: 6px auto 6px;
	width: 80%;
}

#last-sent {
	margin: -5px 0 3px;
}

@media (max-width: 500px) {
	.send-review-invite-modal {
		width: 100%;
		min-width: 200px;
		max-width: 300px;
	}
}
