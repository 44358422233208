.bulk-uploader-csv-organizer-modal {
	display: block;
	min-width: 40%;
}

.csv-column-mapping-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 365px;
	overflow-x: auto;
	width: 100%;
	padding-bottom: 150px;
}

.bulk-csv-mapper .csv-column-mapping-wrapper {
	min-height: 200px;
}

.csv-column-mapping-errorMsgs {
	align-self: center;
	margin-top: 10px;
}

.csv-column-mapping-errorMsg {
	color: red;
}

.csv-property-selector {
	background-color: aliceblue;
	border-style: groove;
	border-width: 2px 2px;
	border-radius: 0px !important;
}

.csv-column {
	width: 80%;
}

#csv-data-cell {
	min-height: 42px;
	border-style: groove;
	padding: 10px;
	border-width: 1px 1px;
	white-space: nowrap;
	overflow: hidden;
}
