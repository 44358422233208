
.gif-image__image-container{
    height: 200px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    list-style: none;
    margin-right: 6px;

    cursor: pointer;
    background-color: yellow;
}

.gif-image__image {
    height: 200px;
}