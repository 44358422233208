.signature__html-signature__preview {
	display: block;
	padding-top: 5px;
	text-decoration: underline;
	cursor: pointer;
}

.signature__html-signature__toggle_preview {
	display: block;
	padding-top: 5px;
	text-decoration: underline;
	cursor: pointer;
}
