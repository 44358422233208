.thread-action {
    margin-right: 15px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    padding: 7px;
    border-radius: 20px;
}

.thread-action:hover {
    opacity: 0.8;
    cursor: pointer;
}

.thread-action--active {
    background: #e1efff96;
}