.mb-reactions {
    height: 20px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 7px;
    margin-bottom: 15px;
}

.mb-reactions-group {
    height: 30px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 3px 7px 0px 7px;
    border: 1px solid #F6F6F9;
    border-radius: 5px;
    margin-right: 7px;
}

.mb-reactions-group:hover {
    border: 1px solid #60A9FF;
    color: #60A9FF;
    cursor: pointer;
}

.mb-reactions-group-emoji {
    font-size: 18px;
}

.mb-reactions-group-count {
    font-size: 12px;
    font-weight: 900;
    padding-left: 5px;
}

.mb-reactions-icon {
    height: 30px;
    padding: 0px 7px 0px 7px;
    border: 1px solid #F6F6F9;
    border-radius: 5px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.mb-reactions-icon:hover {
    border: 1px solid #60A9FF;
    color: #60A9FF;
    cursor: pointer;
}

.mb-reactions-emoji-selector {
    position: absolute;
    bottom: 50px;
    left: 0;
}

.mb-reactions-group:hover > .mb-reactions-users {
    display: block;
}

.mb-reactions-users {
    display: none;
    position: absolute;
    bottom: -10px;
    margin-left: -8px;
    font-size: 10px;
    color: white;
    background-color: black;
    padding: 3px 4px 2px 4px;
    border-radius: 5px;
    opacity: 0.9;
}
