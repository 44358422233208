.lead-source-analytics__charts {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	margin-top: 45px;
}

.lead-source-analytics__chart-section {
	margin-top: 5px;
	margin-bottom: 5px;
	width: 100%;
}

.lead-source-analytics__spinner {
	margin-top: 120px;
}

.DayPickerInput input {
	border-radius: 5px;
}
.DayPickerInput input:focus {
	border-color: #66afe9;
}
