.unsaved-changes__container {
	z-index: 9999;
	position: fixed;
	padding: 4px;
	width: 320px;
	box-sizing: border-box;
	color: #fff;
	bottom: 0px;
	right: 27px;
}

.unsaved-changes__toast {
	position: relative;
	min-height: 64px;
	box-sizing: border-box;
	margin-bottom: 1rem;
	padding: 10px 8px;
	border-radius: 12px;
	display: flex;
	justify-content: space-between;
	max-height: 800px;
	overflow: hidden;

	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	border: 1px solid #60a9ff;
	background-color: #ffffff;
	color: #333333;
}

.unsaved-changes__toast-body {
	margin: auto 0;
	flex: 1 1;
	font-size: 18px;
}

.unsaved-changes__toast-body__text {
	width: 100%;
	text-align: center;
}

.unsaved-changes__buttons {
	margin-top: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.unsaved-changes__buttons .mb-button {
	color: #333333;
	width: 76px;
	text-align: center;
}

.unsaved-changes__buttons .mb-button:first-child {
	margin-left: 0;
}

.unsaved-changes__buttons .mb-button:hover {
	color: #ffffff;
}
