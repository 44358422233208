.condition {
	margin-bottom: 20px;
	padding: 12px 12px 24px 12px;
	border-radius: 5px;
	border: solid 1px #e5e6e7;
	background-color: #ffffff;
	width: 100%;
}

.condition__type {
	display: flex;
	align-items: center;
}

.condition__type--space-between {
	justify-content: space-between;
}

.condition__type__delete {
	margin-left: 15px;
	height: 34px;
	position: relative;
}

.condition__type__operator {
	position: relative;
	margin-right: 5px;
	width: 300px;
}

.condition__type__delete__icon {
	cursor: pointer;
}

.condition__type__description {
	margin-top: 15px;
}

.condition__type__selector {
	width: 100%;
}

.condition .dh-action {
	margin-right: 0;
}

.condition__parameters {
	margin-top: 15px;
}

.condition__parameter {
	margin-bottom: 15px;
	display: flex;
	align-items: center;
}

.condition__param-info {
	margin-top: 15px;
}

.condition__param-info__bubbles {
	display: flex;
	align-items: center;
}

.condition__param-info__bubble {
	border: solid 1px #e5e6e7;
	font-size: 13px;
	padding: 3px 6px 2px;
	border-radius: 20px;
	margin-right: 5px;
	width: fit-content;
}

.condition__param-info__bubble:hover {
	cursor: pointer;
	opacity: 0.6;
}

.condition__param-info__tooltip {
	max-width: 300px;
}

.condition__parameters__add {
	margin-top: 20px;
	color: #999999;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.condition__parameters__add .dh-action {
	margin-right: 0;
}

.condition__parameters__add__icon {
	cursor: pointer;
}

.condition__parameter__name {
	display: flex;
	align-items: center;
	width: 100%;
}

.condition__parameter__name__required {
	margin-top: -10px;
	margin-right: 3px;
	font-size: 24px;
	color: #ed5565;
}

.condition__parameter__name__info {
	margin-right: 10px;
}

.condition__parameter__operator {
	margin-left: 10px;
	margin-right: 10px;
}

.condition__parameter__remove {
	color: #999999;
	position: relative;
	margin-left: 10px;
	margin-top: -4px;
}
