/* gbm = google business messaging */

.gbm {
	min-width: 650px;
	width: 100%;
}

.gbm__loading {
	position: relative;
	width: 500px;
	height: 200px;
}

.gbm__actions {
	margin: 20px 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.gbm__field--no-border {
	height: auto;
	padding: 40px 0 0;
	border: none;
}

.gbm__checkboxes {
	display: flex;
	flex-wrap: wrap;
}

.gbm__checkboxes__option {
	display: flex;
	width: 120px;
	padding: 10px 10px 10px 0;
}

.gbm__checkboxes__option__label {
	margin-left: 5px;
}

.gbm__info {
	width: 650px;
	border: 1px solid #f5f5f5;
	border-radius: 8px;
	padding: 15px 20px;
	font-size: 14px;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.gbm__info__item {
	width: 450px;
	padding: 8px 12px;
}

.gbm__info__item__text {
	display: flex;
	align-items: center;
}

.gbm__info__item__text__icon {
	margin-left: 8px;
	width: 20px;
}

.gbm__info__item__text svg {
	width: 16px;
	height: 16px;
}

.gbm__info__item__logo {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
}

.gbm__info__item__logo__img {
	height: 150px;
	width: 150px;
}

.gbm__info__item--launch,
.gbm__info__item--verify {
	display: flex;
	align-items: center;
}

.gbm__info__item--launch .mb-button,
.gbm__info__item--verify .mb-button {
	margin-left: auto;
}

.gbm__info__item--start {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.gbm__info__item--start__img {
	width: 200px;
	margin: auto;
	margin-bottom: 25px;
}

.gbm__info__actions {
	position: relative;
	align-self: flex-end;
	display: flex;
	justify-content: flex-end;
}

.gbm__info__test-urls {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.gbm__info__test-urls__header {
	margin: 15px auto;
	padding: 10px 0;
	width: 100%;
	text-align: center;
	border-top: 1px solid #f5f5f5;
}

.gbm__info__test-urls__links {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
}

.gbm__info__test-urls__links__link {
	text-decoration: inherit;
	color: inherit;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.gbm__info__test-urls__links__link:hover {
	text-decoration: none;
}

.gbm__form {
	width: 100%;
}

.gbm__form__header {
	width: 550px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.gbm__form__header__text {
	margin: auto;
}

.gbm__form__header__back {
	width: 1px;
}

.gbm__form__conversation-starters-list {
	padding-bottom: 15px;
	border-bottom: 1px solid #f5f5f5;
}

.gbm__form__conversation-starters-list__item {
	margin-top: 10px;
	margin-bottom: 10px;
	width: 100%;
	padding: 15px 20px;
	border: 1px solid #f5f5f5;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
}

.gbm__form__conversation-starters-list__item:hover {
	cursor: pointer;
	transition: background 0.2s;
	border: 1px solid #60a9ff;
}

.gbm__form__conversation-starters-list__item__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.gbm__form__conversation-starters-list__item__header__title {
}

.gbm__form__conversation-starters-list__item__header__actions {
	display: flex;
}

.gbm__form__conversation-starters-list__item__preview {
	margin-bottom: 10px;
	display: inline-block;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 300px;
}

.gbm__form__conversation-starters-list__item__select {
	margin-bottom: 15px;
}

.gbm__form__nl__domains {
	margin-top: 15px;
	position: relative;
	display: flex;
	padding-bottom: 15px;
	border-bottom: 1px solid #f5f5f5;
}

.gbm__form__nl__domains--no-border {
	border: none;
}

.gbm__form__nl__domains__add-input,
.gbm__form__nl__domains__remove-input {
	margin-right: 10px;
	width: 100%;
}

.gbm__form__nl__domains__list {
	padding-bottom: 15px;
	border-bottom: 1px solid #f5f5f5;
}

.gbm__form__nl__domains__list__item {
	margin-top: 10px;
	position: relative;
	display: flex;
}

.gbm__form__agent-logo {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid #f5f5f5;
	padding-top: 15px;
	padding-bottom: 15px;
}

.gbm__form__agent-logo__img {
	border: 1px solid #ccc;
	border-radius: 8px;
	margin: auto;
	width: 100px;
	height: 100px;
}

.gbm__form__loading {
	margin-top: 20px;
}

.gbm__form__options {
	margin-right: 50px;
	max-width: 750px;
	min-width: 400px;
}

.gbm__form__options__field {
	font-size: 14px;
	height: 120px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	border-bottom: 1px solid #f5f5f5;
}

.gbm__form__options__field__label {
	flex: 1;
	margin-right: 20px;
}

.gbm__form__options__field__label__title {
	font-weight: 900;
	display: flex;
}

.gbm__form__options__field__label__description {
	margin-top: 5px;
}

.gbm__form__options__field__input {
	width: 40%;
	margin-left: auto;

	display: flex;
	flex-flow: column nowrap;
	align-items: flex-end;
}

.gbm__form__options__field__input .input {
	width: 100%;
}

/* gbmvm = google business messaging verify modal */
.gbmvm__inputs {
	width: 100%;
	max-width: 400px;
}

.gbmvm__authenticate-button__icon {
	position: relative;
	top: 2px;
}
