.reviews-summary {
	height: 350px;
}

.reviews-summary__header {
	font-size: 21px;
	margin-bottom: 5px;
}

.reviews-summary__list {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.reviews-summary__list__connect-google,
.reviews-summary__list__connect-facebook {
	position: relative;
}

.reviews-summary__list__connect-google:hover,
.reviews-summary__list__connect-facebook:hover {
	cursor: pointer;
}

.reviews-summary__list__connect-google__data,
.reviews-summary__list__connect-facebook__data {
	position: relative;
}

.reviews-summary__list-no-connection {
	position: absolute;
	height: 80px;
	width: 100%;
	background-color: white;
	opacity: 0.8;
	top: 0px;
	align-items: center;
	justify-content: center;
	text-align: center;
	display: flex;
	font-size: 21px;
}

.reviews-summary__list__connect-google-no-connection__icon,
.reviews-summary__list__connect-facebook-no-connection__icon {
	position: relative;
	top: 1;
}

.reviews-summary__list__connect-google__progress__bar {
	width: 94%;
}

.reviews-summary__list__connect-facebook__progress__bar {
	width: 94%;
}

@media screen and (max-width: 768px) {
	.reviews-summary {
		margin-bottom: 25px !important;
	}
}
