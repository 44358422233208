.input-password {
	display: flex;
	position: relative;
	width: 100%;
}

.input-password__btn {
	padding-right: 40px;
}

.input-password__toggle-view {
	height: 28px;
	width: 28px;
	border-radius: 50%;
	position: absolute;
	padding: 5px;
	top: 3px;
	right: 10px;
}

.input-password__toggle-view:hover {
	cursor: pointer;
	background-color: #f3f3f4;
}

.input-password__requirements-table {
	margin-top: 10px;
	margin-bottom: 10px;
	background: #f6f6f9;
	padding: 10px;
	border-radius: 20px;

	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;
}

.input-password__requirements-table__requirement {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	padding-bottom: 2px;
	padding-top: 2px;
}

.input-password__requirements-table__requirement__icon {
	display: flex;
}

.input-password__requirements-table__requirement__icon svg {
	position: relative;
	top: 1px;
}

.input-password__requirements-table__requirement__icon--passed {
	color: #18a689;
}

.input-password__requirements-table__requirement__icon--failed {
	color: #ed5565;
}

.input-password__requirements-table__requirement__label {
	text-align: left;
	padding-left: 10px;
}
