.fbi__content {
	width: 100%;
	background-color: white;

	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	font-size: 16px;
	padding-left: 20px;
}

.fbi__block {
	width: 100%;
}

.fbi__status-label {
	margin-right: 10px;
	font-weight: 700;
}

.fbi__pages-block {
	border-radius: 5px;
	padding: 20px;
}

.fbi__pages {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 20px;
}

.fbi__pages-label {
	display: block;
	margin-right: 10px;
}

.fbi__page {
	min-width: 350px;
	width: 50%;
	box-shadow: 0 6px 8px rgba(102, 119, 136, 0.03), 0 1px 2px rgba(102, 119, 136, 0.3); /*0px 0px 45px -10px rgba( 178, 178, 178, 0.7);*/
	border-radius: 5px;
	padding: 20px;
	margin-right: 20px;
	margin-top: 20px;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.fbi__page__content {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	width: 100%;
}

.fbi__page__content__icon > img {
	border-radius: 5px;
}

.fbi__page > div {
	margin-right: 10px;
}

.fbi__page-subscribe {
	margin-right: 0;
	margin-left: auto;
}

.fbi__page-subscribe--loading {
	margin-right: 25px;
	margin-top: 20px;
}

.fbi__page__content__title {
	margin-left: 10px;
	padding-top: 5px;
	font-size: 16px;
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.fbi__page__subd-to {
	margin-top: 10px;
	align-self: center;
	opacity: 0.6;
	font-size: 12px;
}
