.bulk-actions__cards {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
}

.bulk-actions__card {
	width: 400px;
	margin: 0 10px;
}

.bulk-actions-limit-reached-message {
	width: 400px;
	font-size: 14px;
}

.bulk-actions-limit-reached-message h4 {
	text-align: left;
	font-weight: 600;
	font-size: 17px;
	margin-top: 15px;
	margin-bottom: 15px;
}

.bulk-actions-options {
	width: 300px;
}

.bulk-actions-card {
	padding: 20px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	margin: 15px 0;
	border-radius: 5px;
	transition: 0.3s;
	cursor: pointer;
	background-color: #ffffff;
}

.bulk-actions-card:hover {
	box-shadow: 0 4px 17px 7px rgba(62, 68, 77, 0.1);
}

.bulk-action-card_icon_small {
	position: relative;
	top: 2px;
}

.bulk-action-card_rounded {
	border-radius: 15px !important;
}

.bulk-action-schedule-type-select {
	width: 300px;
	margin: 0 auto;
}

.bulk-action-confirm-block-content {
	width: 100%;
	min-width: 200px;
}

.bulk-action-confirm-block-content h4 {
	text-align: center;
}

.bulk-action-confirm-block-content__icon {
	position: relative;
	top: 3px;
}

.bulk-action-confirm-block-content__icon svg {
	width: 16px;
	height: 16px;
}

.bulk-action-confirm-block-content__schedule-for {
	margin: 15px 0 5px;
	width: 300px;
	margin: 0 auto;
}

.bulk-action-confirm-block-content__schedule-for__date-picker {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 16px;
}

.bulk-action-confirm-block-content__schedule-for__date-picker .date {
	font-size: 16px;
}

.bulk-action-confirm-block-content__warning {
	display: flex;
	justify-content: center;
	width: 350px;
	margin: auto;
	margin-top: 20px;
}

.bulk-action-cs-buttons {
	display: flex;
	justify-content: space-between;
}
.bulk-action-cs-buttons button {
	margin: 5px;
}

.bulk-contact-upload {
	width: 780px;
	max-width: 780px;
	min-width: 780px;
	min-height: 300px;
	font-size: 14px;
}

.bulk-contact-upload .contact-selector__update-contacts-header {
	font-size: 13px;
}

.bulk-actions__csv-upload__warning {
	padding: 10px 20px;
	background-color: #ffffcc;
	border-color: #ffffcc;
	border-radius: 8px;
	width: fit-content;
	margin: auto;
	margin-bottom: 15px;
}

@media screen and (max-width: 900px) {
	.bulk-actions__cards {
		align-items: flex-start;
		justify-content: center;
	}

	.bulk-contact-upload {
		max-width: 100%;
		min-width: 100%;
		width: 100%;
	}
}

@media screen and (max-width: 600px) {
	.bulk-actions__cards {
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}

	.bulk-actions__card {
		max-width: 400px;
		width: auto;
	}
}
