/* mcm = merge-contact-modal */
.mcm-modal {
	min-width: 650px;
	width: 650px;
}

.mcm {
	width: 100%;
}

.mcm__header {
	font-size: 16px;
}

.mcm__description {
	font-size: 14px;
}

.mcm__merge-list {
	width: 100%;
}

.mcm__merge-list__item {
	display: flex;
	padding: 8px 5px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mcm__merge-list__item:hover {
	background-color: rgba(96, 169, 255, 0.2);
	cursor: pointer;
	transition: background 0.2s;
}

.mcm__merge-list__item__field {
	font-weight: 600;
	width: 30%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.mcm__merge-list__item__value {
	width: 35%;

	display: flex;
}

.mcm__merge-list__item__value__text {
	margin-left: 5px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.mcm__merge-list__item__value__text--change {
	color: #60a9ff;
}
