.mb-ccsmli__item{
    width: calc(100% - 40px);
    box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
    margin: 5px 20px 15px 20px;
    border-radius: 8px;
    border: 1px solid white;
    padding: 15px 15px;
}

.mb-ccsmli__item__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mb-ccsmli__item__header__text{
    display: flex;
    flex-direction: row;
}

.mb-ccsmli__item__content{
    margin-top: 10px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
}

.mb-ccsmli__item__header__actions {
    margin-left: auto;
    display: flex;
    position: relative;
}