.reset-password {
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	max-width: 400px;
	width: 400px;
	margin: 8% auto 50px;
	font-size: 14px;
}

.reset-password--reset-page {
	max-width: 350px;
	width: 350px;
	text-align: left;
}

.reset-password__buttons {
	margin: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.reset-password__buttons .mb-button {
	margin-right: 10px;
}

.reset-password__reset-btn {
	display: flex;
	justify-content: center;
	align-items: center;
}
.reset-password__copyright {
	color: #aaaaaa;
	font-size: 10px;
	position: absolute;
	bottom: 10px;
	right: 10px;
}

.reset-password__text-success {
	color: #18a689;
}

@media screen and (max-width: 377px) {
	.reset-password__recaptcha--scale {
		transform: scale(0.8);
		-webkit-transform: scale(0.8);
		transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
	}
}
@media screen and (max-width: 330px) {
	.reset-password__recaptcha--scale {
		transform: scale(0.7);
		-webkit-transform: scale(0.7);
		transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
	}
}
@media screen and (max-width: 280px) {
	.reset-password__recaptcha--scale {
		transform: scale(0.6);
		-webkit-transform: scale(0.6);
		transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
	}
}

@media only screen and (max-width: 420px) {
	.reset-password {
		width: 85%;
	}
}

@media only screen and (max-width: 380px) {
	.reset-password {
		padding-left: 15px;
		padding-right: 15px;
	}
}
