
/* react-datepicker styles */
.react-datepicker {
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.react-datepicker__day:hover {
	border-radius: 4px;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
	border-radius: 4px;
	background-color: #60a9ff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover {
	background-color: #2d8dff;
}

.react-datepicker__time-list-item--selected {
	background-color: #60a9ff !important;
}

.react-datepicker,
.react-datepicker-time__header {
	font-size: 1em;
}
.react-datepicker__header {
	padding-top: 0.8em;
}
.react-datepicker__month {
	margin: 0.4em 1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
	width: 1.9em;
	line-height: 1.9em;
	margin: 0.166em;
}
.react-datepicker__current-month {
	font-size: 1em;
}
.react-datepicker__navigation {
	top: 1em;
	line-height: 1.7em;
	border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
	border-right-color: #ccc;
	left: 1em;
}
.react-datepicker__navigation--next {
	border-left-color: #ccc;
	right: 1em;
}
