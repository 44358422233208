.sign-ups {

}

.sign-ups__text-area {
    height: 300px;
}

/* manage-sign-ups */
.msu {
    width: 100%;
    margin-bottom: 150px;
}

.msu-state {
    background-color: #c7e0ff;
    border-radius: 8px;
    padding: 15px 20px;
    display: flex;
    font-size: 16px;
}

.msu-state__text:first-letter {
    text-transform: uppercase;
}

.msu__form {
    width: 100%;
    max-width: 600px;
    margin: auto;
}

.msu__form__buttons {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.msu__form__input__checkbox {
    
}

.msu__form__input__select {
    z-index: 10001;
    border-radius: 8px;
}

.msu__form__input__select > div {
    border-radius: 8px;
}

.msu__form__auto-complete {
    display: flex;
}

.msu__form__auto-complete__input {
    width: 100%;
}

.msu-modal__body {
    width: 400px;
}

.msu-modal__body .prd-timeline {
    width: 400px;
    margin-left: 0;
    margin-bottom: 20px;
}

.msu-modal__body .dh-card {
    width: 400px;
}