.select {
	margin-bottom: 15px;
	position: relative;
	width: 100%;
}

.select__label {
	font-size: 12px;
	margin-bottom: 5px;

	font-weight: 900;
	font-size: 12px;
}

.select__description {
	font-style: italic;
	font-size: 12px;
}

.select__input {
	border: 1px solid #e5e6e7;
	border-radius: 8px;
	height: 40px;
	font-size: 14px;
	padding-left: 10px;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	width: 100%;
}

.select__input:focus {
	border-color: #66afe9;
}

.select__input:disabled {
	cursor: not-allowed;
	background-color: #eee;
	opacity: 1;
}

.select__phantom-input {
	position: absolute;
	border: 0;
	color: transparent;
	background-color: transparent;
	top: 40;
	left: 50;
	width: 0;
	height: 0;
	z-index: 0;
}

.select__label__required {
	color: rgba(255, 99, 132, 1);
}
