.mb-tcml {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 20px;
    flex-grow: 1;
}

.mb-tcml-load-more
 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25px;
}

.mb-tcml-load-more-button {
    align-self: center;
    margin-left: 10px;
    font-size: 14px;
    padding: 6px 15px 4px 15px;
    border-radius: 50px;
    border: 2px solid #60A9FF;
    margin: 10px 0 10px 0;
}

.mb-tcml-load-more-button:hover {
    border: 2px solid #60A9FF;
    background-color: #60A9FF;
    color: white;
    cursor: pointer;
}

.mb-tcml-newest {
    position: sticky;
    left: calc(50% - 17px);
    bottom: 10px;
    height: 34px;
    width: 34px;
    font-size: 14px;
    padding: 6px 15px 4px 5px;
    border-radius: 50px;
    border: 2px solid #60A9FF;
    background-color: #60A9FF;
    color: white;
    margin: 10px 0 10px 0;
    z-index: 1002;
    transition: background 0.2s, border 0.2s;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

.mb-tcml-newest:hover {
    opacity: 0.8;
    cursor: pointer;
}

.mb-tcml-newest-unread {
    position: sticky;
    left: calc(50% - 100px);
    bottom: 10px;
    height: 34px;
    width: 200px;
    font-size: 14px;
    padding: 6px 15px 4px 5px;
    border-radius: 50px;
    border: 2px solid #ec4758;
    background-color: #ec4758;
    color: white;
    margin: 10px 0 10px 0;
    z-index: 1002;
    transition: background 0.2s, border 0.2s;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.mb-tcml-newest-unread:hover {
    opacity: 0.8;
    cursor: pointer;
}