.account-setup {
	margin: 20px auto 20px auto;
	border-radius: 8px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px #e7eaec;
	width: 400px;
}

.account-setup__loading {
	width: 400px;
	margin: auto;
}

.account-setup__header {
	padding: 10px 20px;
	font-size: 21px;
	margin-bottom: 5px;
}

.account-setup__items {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.account-setup__items__item {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 40px;
	border-top: solid 1px rgba(0, 0, 0, 0.1);
	padding: 10px 20px;
}

.account-setup__items__item:first-child {
	border: none;
}

.account-setup__items__item:hover {
	background-color: #eef6ff;
	cursor: pointer;
}

.account-setup__items__item__icon {
	margin-right: 20px;
	margin-top: 5px;
}

.account-setup__items__item__text {
}

.account-setup__items__item__text__info {
	margin-left: 5px;
}
