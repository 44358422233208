.scheduled-messages__header {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: flex-start;
	padding: 0 0 15px 30px;
}

.scheduled-messages__header__actions {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	margin-bottom: auto;
	padding-right: 15px;
}

.scheduled-messages__header__date-picker {
	position: relative;
}

.scheduled-messages__header__search .dh-search {
	margin-bottom: 10px;
}

.scheduled-messages__header__date-picker__label {
	position: relative;
	padding: 0 30px 2px 2px;
	width: fit-content;
}

.scheduled-messages__header__date-picker__inputs {
	position: relative;
}

.scheduled-messages__header__date-picker__inputs .InputFromTo {
	display: flex;
}

.scheduled-messages__modal-button {
	margin-left: auto;
}

.scheduled_messages__record-actions {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
}

.scheduled-messages__no-data-img {
	height: 100%;
	width: 100%;
}
