.dh-list {
	width: 100%;
	min-height: 200px;
	flex-grow: 1;
	overflow: hidden;
}

#data-type-selector {
	max-width: 400px;
	width: 200px;
	font-size: 16px;
}

.dh-list-loadmore {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	width: 150px;
	margin-top: 10px;
	margin-bottom: 30px;
	margin-left: auto;
	margin-right: auto;
	font-size: 14px;
	padding: 6px 10px 4px 10px;
	border-radius: 50px;
	align-self: flex-end;
	border: 2px solid #60a9ff;
}

.dh-list-loadmore:hover {
	border: 2px solid #60a9ff;
	background-color: #60a9ff;
	color: white;
	cursor: pointer;
}

.dh-list-header {
	min-height: 45px;
	height: 45px !important;
	border-top: 1px solid #f5f5f5;
	overflow-y: scroll;
}

.dh-list-record {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	height: 40px;
	font-size: 16px;
	padding-left: 40px;
	width: 100%;
}

.dh-list-record:hover {
	background-color: rgba(96, 169, 255, 0.2);
	cursor: pointer;
	transition: background 0.2s;
}

.dh-list-record__cell {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	padding-right: 3px;
	padding-left: 3px;
}

.dh-list-record--odd {
	background-color: #f6f6f9;
}

.dh-list-record--striped:nth-child(odd) {
	background-color: #f6f6f9;
}

.dh-list-record--striped:nth-child(odd):hover {
	background-color: rgba(96, 169, 255, 0.2);
	cursor: pointer;
	transition: background 0.2s;
}

.dh-list-record-auto {
	flex: auto;
}

.dh-list-record-none {
	flex: none;
}

.dh-list-record-5 {
	flex: 5;
}

.dh-list-record-4 {
	flex: 4;
}

.dh-list-record-3 {
	flex: 3;
}

.dh-list-record-2 {
	flex: 2;
}

.dh-list-record-1 {
	flex: 1;
}

.dh-list-record-0 {
	display: none;
}

.dh-list-none {
	font-size: 24px;
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}

.dh-list-none__graphic {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	width: 35%;
	height: 35%;
}

.dh-list-none__text {
	margin-top: 40px;
	text-align: center;
	font-size: 20px;
	max-width: 50%;
	color: #868b98;
}

.dh-list-none__button {
	margin-left: auto !important;
	margin-right: auto !important;
	margin-top: 10px;
}

.not-sortable {
	cursor: default;
}

.dh-list__actions {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.dh-list-pagination-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;

	width: 100%;
	padding: 4px 35px;

	/* Make text not selectable */
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.dh-list-pagination-header__actions {
	display: flex;
	flex-direction: row;
	margin-left: 10px;
}

.dh-list-pagination-header__actions__previous > svg,
.dh-list-pagination-header__actions__next > svg {
	position: relative;
	top: 2px;
}

.dh-list-pagination-header__page-count {
	margin-left: 10px;
}

.dh-list-pagination-header__limit-select {
	width: 150px;
}

.dh-list-pagination-header__limit-select > .select {
	margin-bottom: 0;
}

/* xs styles */
@media only screen and (max-width: 575px) {
	.dh-list-hidden-xs {
		display: none;
	}

	.dh-list-record-xs-3 {
		flex: 3;
	}

	.dh-list-record-xs-2 {
		flex: 2;
	}

	.dh-list-record-xs-1 {
		flex: 1;
	}
}

/* sm styles */
@media only screen and (max-width: 767px) {
	.dh-list-hidden-sm {
		display: none;
	}

	.dh-list-record-sm-3 {
		flex: 3;
	}

	.dh-list-record-sm-2 {
		flex: 2;
	}

	.dh-list-record-sm-1 {
		flex: 1;
	}

	.dh-list-record {
		padding-left: 10px;
	}
	#data-type-selector {
		width: 100%;
	}
}

/* md styles */
@media only screen and (max-width: 991px) {
	.dh-list-hidden-md {
		display: none;
	}

	.dh-list-record-md-3 {
		flex: 3;
	}

	.dh-list-record-md-2 {
		flex: 2;
	}

	.dh-list-record-md-1 {
		flex: 1;
	}
}

/* lg styles */
@media only screen and (min-width: 992px) {
	.dh-list-hidden-lg {
		display: none;
	}

	.dh-list-record-lg-3 {
		flex: 3;
	}

	.dh-list-record-lg-2 {
		flex: 2;
	}

	.dh-list-record-lg-1 {
		flex: 1;
	}
}
