.mb-conversation-filter-list {
    position: fixed;
    top: 100px;

    width: 300px;
    min-height: 300px;
    max-height: 500px;
    
    background-color: white;
    border-radius: 8px;
    padding: 10px 0 0 0px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    
    overflow: auto;
    
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    z-index: 9999999;
}

.mb-conversation-filter-item {
    width: 100%;
    min-height: 40px;
    font-size: 16px;
    margin-bottom: 5px;
    padding: 0 15px 0 15px;
    margin-bottom: 5px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.mb-conversation-filter-item:hover {
    cursor: pointer;
    background-color: rgba(96, 169, 255, 0.2);
    transition: background 0.2s, border 0.2s;
}

.mb-conversation-filter-item__selected {
    margin-top: 3px;
    margin-left: auto;
}