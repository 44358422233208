.mb-result-list {

}

.mb-result-list-title {
    font-size: 16px;
    text-align: center;
    margin: 15px;
}

.mb-result-list-title-content {
    border-radius: 100px;
    background-color: #F6F6F9;
    padding: 6px 8px 4px 8px;
}
