.mb-send {
    height: 40px;
    font-size: 16px;
    border-radius: 8px;
    color: white;
    line-height: 1.6;
    margin-bottom: 5px;
    background-color: #60A9FF;
    line-height: 35px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.mb-send--auto {
    margin-left: auto
}

.mb-send--text {
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.mb-send--chevron {
    padding-top: 7px;
    padding-right: 5px;
    padding-left: 5px;
    border-left: 1px solid #4299ff;
}

.mb-send__context-menu {
    position: absolute;

    background-color: white;
    padding: 10px 0 10px 0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    border-width: 0;
    border-radius: 3px;
    z-index: 1002;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    right: 30px;
    bottom: 65px;
}

.mb-send__context-menu-item {
    font-size: 15px;
    color: #333;
    padding-left: 10px;
    width: 150px;
}

.mb-send__context-menu-item:hover {
    background-color: #60A9FF;
    color: white;
    cursor: pointer;
}

.mb-send:hover {
    /* opacity: 0.8; */
    cursor: pointer;
}

.mb-send--disabled {
    opacity: 0.5;
}

.mb-send__picker {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    z-index: 1001;
}

.mb-send__contains-media {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.mb-send__contains-media > .mb-media__image,
.mb-send__contains-media > .mb-media__file {
    margin-right: 10px;
}

.mb-send__picker .react-datepicker-wrapper:first-child {
    margin-right: 10px;
}

.mb-send__picker__time {
    width: 100px;
}

.mb-send__picker__date {
    width: 150;
    margin-right: 15px;
}

.mb-send__description {
    text-align: center;
    width: 100%;
}

.mb-send-later-modal-content {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.mb-send-later-modal-content__message {
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    margin: auto;
    margin-bottom: 15px;
    padding: 0;
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
}

.mb-send-later-actions {
    margin-top: 50px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    align-self: flex-end;
}

.mb-send-later-actions > .mb-button {
    margin-left: 10px;
}