.uim {
    min-width: 800px; 
    min-height: 700px;

    display: flex;
    flex-flow: column nowrap;
}

.uim__actions {
	margin-top: auto;
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

.uim__input {
    border: 1px solid #e5e6e7;
    border-radius: 8px;
    height: 40px;
    font-size: 14px; 
    padding-left: 10px; 
}

.uim__input--wide {
    min-width: 300px;
}

.uim__input--small {
    max-width: 40px;
    margin-left: 10px;
    margin-right: 10px;
}

.uim__label {
	margin-top: 20px;
	margin-bottom: 5px;
	margin-left: 3px;
}

.uim__label--large {
    font-size: 14px;
}

.uim__details {
    width: 100%;
    flex: 1;
    padding: 5px 20px;

    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.uim__users {
    width: 100%;
    flex: 1;
    padding: 5px 20px;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
}

.uim__users__empty {
    margin-top: 120px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    color: #9c9c9c;
}

.uim__users__empty__img {
    max-width: 250px;
    margin-bottom: 40px;
}

.uim__user__checkbox {
    height: 100%;
    margin-top: 6px;
    /* margin-bottom: 12px; */
}

.uim__rules {
    width: 100%;
    flex: 1;
    padding: 5px 20px;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
}

.uim__rules__top {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.uim__rules__top__text {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.uim__rules__top__toggle {
    margin-left: auto;
    margin-right: 30px;
}
 
.uim__rules__top__title {
    font-size: 16px;
    font-weight: 900;
}

.uim__rules__top__subtitle {
    margin-top: 10px;
    font-size: 14px;
}

.uim__rules__spacer {
    width: 95%;
    height: 1px;
    background-color: #e4e9f0;
    margin-top: 25px;
    margin-bottom: 25px;
    align-self: center;
}

.uim__rules__actions {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    margin-bottom: 30px;
}

.uim__rules__add-button {
    margin-left: auto !important;
}

.uim__rules__no-rules {
    margin-top: 60px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    color: #9c9c9c;
}

.uim__rules__no-rules__img {
    max-width: 250px;
    margin-bottom: 40px;

}

.uim__rule {
    background-color: #F6F6F9;

    width: 500px;
    padding: 10px 20px;
    border-radius: 8px;
    margin-bottom: 15px;
}

.uim__rule__top {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.uim__rule__top__delete {
    margin-left: auto;
    width: 20px;
    height: 20px
}

.uim__rule__top__delete:hover {
    cursor: pointer;
}

.uim__rule__error {
    color: #ec4758;
    border: 1px solid #ec4758;
    background-color: rgba(255, 51, 72, 0.1);
    border-radius: 8px;
    padding: 15px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.uim__rule__category {
    background-color: white;

    width: 100%;
    padding: 15px 20px;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #e5e6e7;
}


.uim__rule__category:hover {
    background-color: rgba(96, 169, 255, 0.2);
	cursor: pointer;
	transition: background 0.2s;
    border: 1px solid #60a9ff;
}


.uim__rule__category__detail {

    margin-top: 10px;
    background-color: #F6F6F9;
    width: 100%;
    border: 1px solid #e5e6e7;
    border-radius: 8px;
}

.uim__rule__category__detail__top {
    background-color: white;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 100%;
    padding: 15px 20px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.uim__rule__category__detail__top__close {
    margin-left: auto;
    margin-top: 2px;
    width: 16px;
}

.uim__rule__category__detail__top__close:hover {
    cursor: pointer;
}

.uim__rule__category__detail__body {
    padding: 15px 20px;
}

.uim__rule__action {
    margin-top: 10px;
    background-color: white;
    width: 100%;
    border: 1px solid #e5e6e7;
    border-radius: 8px;
}

.uim__rule__action:hover {
    background-color: rgba(96, 169, 255, 0.2);
	cursor: pointer;
	transition: background 0.2s;
    border: 1px solid #60a9ff;
}

.uim__rule__action__top {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.uim__rule__action__repeat {
    padding: 15px 15px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.uim__rule__action__repeat__text {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;

    margin-right: 20px;
}

.uim__rule__action__repeat__text__title {
    font-weight: 900;
}

.uim__rule__action__repeat__text__subtitle {
    margin-top: 5px;
}

.uim__rule__action__repeat__toggle {
    margin-left: auto;
}

.uim__rule__action__top__radio {
    flex-grow: 1;
    padding: 15px 15px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.uim__rule__action__top__radio__button {
    width: 10px;
    height: 10px;
    border-radius: 20px;
    background-color: white;
    margin-right: 10px;
    border: 1px solid #e5e6e7;
}

.uim__rule__action__top__radio__button--active {
    background-color: #60a9ff;
    border: 1px solid #60a9ff;
}

.uim__rule__action__top__select {
    margin: 15px 15px;
    margin-left: auto;
    width: 200px;
}