.mb-etc {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    align-items: center;

    min-width: 800px;
    min-height: 600px;
    border-radius: 8px;
    padding: 15px;
}

.mb-etc__general {
    width: 100%;
    height: 100%;
}


.mb-etc__general__actions {
    display: flex;
    flex-flow: row nowrap;
    align-self: flex-end;
    justify-content: flex-end;
    margin-top: auto;
}

.mb-etc-icon {
    margin-left: auto;
}

.mb-etc-icon:hover {
    opacity: 0.8;
    cursor: pointer;
}

/* np = notification preferences */
.mb-etc__np {
    margin-top: 20px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.mb-etc__np__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #f2f2f2;
    padding: 10px;
    width: 400px;
    height: 100px;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
}

.mb-etc__np__item--selected {
    border: 1px solid #60A9FF;
}

.mb-etc__np__item-radio {
    background-color: #f2f2f2;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-left: 10px;
}

.mb-etc__np__item-radio--selected {
    background-color: #60A9FF;
}

.mb-etc__np__item-content {
    margin-left: 20px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 300px;
}

.mb-etc__np__item-content-title {
    font-size: 18px;
    margin-bottom: 5px;
}

.mb-etc__np__item:hover {
    cursor: pointer;
}

.mb-etc__members {
    margin-top: 20px;
}

.mb-etc__members__add {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: #60A9FF;
    margin-top: 10px;
}

.mb-etc__members__add:hover {
    opacity: 0.8;
    cursor: pointer;
}

.mb-etc__members__add__title {
    margin-left: 5px;
}

.mb-etc__members__add__icon {
    color: #60A9FF;
}

.mb-etc__members__list {
    margin-top: 5px;
    border-radius: 10px;
    border: 1px solid #f2f2f2;
    width: 400px;
}

.mb-etc__members__list__item {
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    padding: 10px 20px;
    align-items: center;
    justify-content: flex-start;
    font-size: 15px;
    border-bottom: 1px #f2f2f2 solid;
}

.mb-etc-member-item-status {
    margin-left: 15px;
    border-radius: 100px;
    font-size: 12px;
    color: #60A9FF;
    border: 1px solid #60A9FF;
    padding: 2px 5px 0px 5px;
    height: 25px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.mb-etc-member-item-status-red {
    margin-left: 15px;
    border-radius: 100px;
    font-size: 12px;
    color: #ff6060;
    border: 1px solid #ff6060;
    padding: 2px 5px 0px 5px;
    height: 25px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.mb-etc-member-item-status-red--button:hover {
    background-color: #ff6060;
    color: white;
    cursor: pointer;
}

.mb-etc-member-item-status--button:hover {
    background-color: #60A9FF;
    color: white;
    cursor: pointer;
}

.mb-etc-member-item-status--button:hover {
    background-color: #60A9FF;
    color: white;
    cursor: pointer;
}

.mb-etc__members__list__item__remove-icon {
    margin-left: auto;
    color: #ec4758;
}

.mb-etc__members__list__item__remove-icon:hover {
    opacity: 0.8;
    cursor: pointer;
}

.mb-etc__members__list__item__add-icon {
    margin-left: auto;
    color: #60A9FF;
}

.mb-etc__members__list__item__add-icon:hover {
    opacity: 0.8;
    cursor: pointer;
}

.mb-etc__members__actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    height: 60px;
}

.mb-etc__members__actions__item {
    border-radius: 100px;
    font-size: 14px;
    border: 2px solid #60A9FF;
    padding: 6px 15px 4px 15px;
    margin-right: 10px;
}

.mb-etc__members__actions__item--save {
    border: 2px solid #60A9FF;
}

.mb-etc__members__actions__item--cancel {
    border: 2px solid #ec4758;
}

.mb-etc__members__actions__item--save:hover {
    color: white;
    background-color: #60A9FF;
    cursor: pointer;
}

.mb-etc__members__actions__item--cancel:hover {
    color: white;
    background-color: #ec4758;
    cursor: pointer;
}

.mb-etc__members__leave {
    margin-top: 10px;
    color: #ec4758;
}

.mb-etc__members__leave:hover {
    opacity: 0.8;
    cursor: pointer;
}

.mb-etc__purge__action {
    margin-top: 20px;
}

.mb-etc__purge__dates__datepicker {
    margin-bottom: 20px;
}

.mb-etc__purge {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #f2f2f2;
    padding: 50px;
}

.mb-etc__purge__dates__datepicker__label{
    display: flex;
}

.mb-etc__purge__dates__datepicker__label__info_icon{
    padding-left: 5px;
}


.mb-etc__action-rows {
	width: 100%;
	flex: 1;
	padding: 5px 20px;

	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: flex-start;
}

.mb-etc__action-rows__row {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.mb-etc__action-rows__row__text {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	max-width: 610px;
}

.mb-etc__action-rows__row__toggle {
	margin-left: auto;
}

.mb-etc__action-rows__row__button {
	margin-left: auto;
}

.mb-etc__action-rows__row__title {
	font-size: 16px;
	font-weight: 900;
}

.mb-etc__action-rows__row__subtitle {
	margin-top: 10px;
	font-size: 14px;
}

.mb-etc__action-rows__spacer {
	width: 95%;
	height: 1px;
	background-color: #e4e9f0;
	margin-top: 25px;
	margin-bottom: 25px;
	align-self: center;
}

.mb-etc__select {
    width: 230px;
}

.mb-etc__media__user_filter {
    margin-bottom: 20px;
    width: 350px;
    z-index: 3;
}