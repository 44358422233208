.mb-chatbots {
    position: fixed;
    z-index: 1020;
    bottom: 0;
    height: 400px;
    background-color: white;
    margin: 10px 20px 59px 20px;
    border-radius: 8px;
    /* border: 1px solid #E6E6E6; */
    /* padding: 15px; */
    overflow-y: hidden;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.mb-chatbots--collapsed {
    width: calc(100% - 700px);
}

.mb-chatbots--expanded {
    width: calc(100% - 880px);
}

.mb-chatbots-header {
    font-size: 22px;
    margin: 15px 0 10px 15px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 96%;
}

.mb-chatbots-header-title {
}

.mb-chatbots-header-x {
    cursor: pointer;
}

.mb-chatbots-header-x:hover {
    opacity: 0.8;
}

.mb-chatbots-search-box {
    width: 97%;
    margin: 0px 0 15px 5px;
}

.mb-chatbots-list {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
}

.mb-chatbots-list-item {
    padding-bottom: 15px;
    padding: 15px 15px 15px 15px;
    border-bottom: 1px solid #E6E6E6;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.mb-chatbots-no-items {
    padding-bottom: 15px;
    padding: 15px 15px 15px 15px;
    display: flex;
    font-size: 15px;
    flex-flow: row nowrap;
    align-items: center;
}

.mb-chatbots-list-item:hover {
    cursor: pointer;
    background-color: #F1F1F1;
}

.mb-chatbots-list-item-content {
}

.mb-mentions-list-item-content--selected {
    background-color: #F1F1F1;
}

.mb-mentions-list-item-content--selected:hover {
    background-color: #e0e0e0;
}

.mb-chatbots-list-item-content:hover {
    /* color: #60A9FF; */
}

.mb-chatbots-list-item-content-name {
    font-size: 18px;
    margin-bottom: 5px;
}

.mb-chatbots-list-item-content-text {
    font-size: 16px;
}

.mb-chatbots-list-item-actions {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto
}

.mb-chatbots-list-item-action {
    margin-right: 10px;
}

.mb-chatbots-list-item-action:hover {
    opacity: 0.8;
}
