.mup-location {
	margin-top: 5px;
}

.mup-notifications-mup-container {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
}

.mup-notifications {
	display: flex;
	flex-flow: column nowrap;
}

.mup-notifications-header {
	height: 50px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.mup-notifications-title {
	width: 200px;
}

.mup-notifications-title-disabled {
	opacity: 0.6;
}

.mup-notifications-item {
	height: 50px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.mup-notifications-flag {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	width: 80px;
}

.mup-notifications-card-header {
	max-width: 410px;
	margin-top: 15px;
}

.mup-notifications-card {
	max-width: 410px;
	padding: 15px;
	border-style: solid;
	border-width: 1px;
	border-radius: 5px;
	/* border: solid 1px #676a6c; */
	margin: 20px 0 15px 0;
}

.mup-notifications-card__select {
	margin-top: 10px;
	width: 60%;
}

/* The mup-container */
.checkbox-container {
	display: block;
	position: relative;
	margin-bottom: 12px;
	width: 19px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 1;
	width: 1;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 19px;
	width: 19px;
	border-radius: 3px;
	background-color: #eee;
}

.checkmark-disabled {
	background-color: #eee;
	cursor: not-allowed;
	pointer-events: all !important;
}

.checkmark--radio {
	border-radius: 50% !important;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
	background-color: #ccc;
}

.checkbox-container-disabled:hover input ~ .checkmark {
	background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
	background-color: #60a9ff;
}

.checkbox-container-disabled input:checked ~ .checkmark {
	background-color: #60a9ff;
	opacity: 0.5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
	left: 6px;
	top: 3px;
	width: 7px;
	height: 11px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.manage-user__signature-checkmark-container {
	margin-bottom: 5px;
}

.manage-user__signature-checkmark {
	display: inline-block;
}

.manage-user__signature-checkmark-span {
	position: relative;
	margin-left: 15px;
	background-color: white;
}

.manage-user__click-to-send {
	margin-top: 20px;
}

.manage-user__click-to-send__option {
	display: flex;
	flex-direction: row;
	width: fit-content;
}

.manage-user__click-to-send__option:hover {
	opacity: 0.6;
	cursor: pointer;
}

.manage-user__click-to-send__option__checkbox {
	margin-right: 10px;
}

.manage-user__click-to-send__option__label {
	font-weight: 600;
}
