.modal__overlay {
	z-index: 99999;
}

.modal__content-container {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 15px;
}

.modal__content {
	color: #333;
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	min-width: 500px;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: center;
	border-radius: 8px;

	padding: 0;
	z-index: 999999999;
	margin: auto;
	margin-top: 45px;
}

.modal__content__header {
	height: 50px;
	margin-bottom: 10px;
	font-size: 28px;
	align-self: flex-start;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	padding-right: 35px;
}

.modal__content__body {
	width: 100%;
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: center;
}

.modal__flex-container {
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.modal__body {
	width: 100%;
}

.modal__field {
	margin-top: 12px;
	margin-bottom: 5px;
	margin-left: 3px;
}

.modal__text--faded {
	opacity: 0.5;
}

.modal__actions {
	display: flex;
	flex-flow: row nowrap;
	align-self: flex-end;
	justify-content: flex-end;
	margin-top: 35px;
}

.modal__actions--space-between {
	justify-content: space-between !important;
}

.modal__field__row {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.modal__field__row__item {
	margin: 12px 5px 5px;
}

.modal__field__row__item--checkbox {
	position: relative;
	top: -5px;
}

.modal__attachments {
	width: 100%;
	margin-top: 10px;
	position: relative;
}

/* Silly EI11 fixes */
@media all and (-ms-high-contrast: none) {
	.modal__content {
		display: block;
	}
}

@media (max-width: 575px) {
	.modal__content {
		min-width: 200px;
		width: 100%;
	}
}
